"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUNTRIES = void 0;
exports.COUNTRIES = [
    {
        ordem: 1,
        nome: 'Afeganistão',
        engName: 'Afghanistan',
        sigla2: 'AF',
        sigla3: 'AFG',
        codigo: '004',
    },
    {
        ordem: 2,
        nome: 'África do Sul',
        engName: 'South Africa',
        sigla2: 'ZA',
        sigla3: 'ZAF',
        codigo: '710',
    },
    {
        ordem: 3,
        nome: 'Albânia',
        engName: 'Albania',
        sigla2: 'AL',
        sigla3: 'ALB',
        codigo: '008',
    },
    {
        ordem: 4,
        nome: 'Alemanha',
        engName: 'Germany',
        sigla2: 'DE',
        sigla3: 'DEU',
        codigo: '276',
    },
    {
        ordem: 5,
        nome: 'Andorra',
        engName: 'Andorra',
        sigla2: 'AD',
        sigla3: 'AND',
        codigo: '020',
    },
    {
        ordem: 6,
        nome: 'Angola',
        engName: 'Angola',
        sigla2: 'AO',
        sigla3: 'AGO',
        codigo: '024',
    },
    {
        ordem: 7,
        nome: 'Anguilla',
        engName: 'Anguilla',
        sigla2: 'AI',
        sigla3: 'AIA',
        codigo: '660',
    },
    {
        ordem: 8,
        nome: 'Antártida',
        engName: 'Antarctica',
        sigla2: 'AQ',
        sigla3: 'ATA',
        codigo: '010',
    },
    {
        ordem: 9,
        nome: 'Antígua e Barbuda',
        engName: 'Antigua and Barbuda',
        sigla2: 'AG',
        sigla3: 'ATG',
        codigo: '028',
    },
    {
        ordem: 10,
        nome: 'Antilhas Holandesas',
        engName: 'Netherlands Antilles',
        sigla2: 'AN',
        sigla3: 'ANT',
        codigo: '530',
    },
    {
        ordem: 11,
        nome: 'Arábia Saudita',
        engName: 'Saudi Arabia',
        sigla2: 'SA',
        sigla3: 'SAU',
        codigo: '682',
    },
    {
        ordem: 12,
        nome: 'Argélia',
        engName: 'Algeria',
        sigla2: 'DZ',
        sigla3: 'DZA',
        codigo: '012',
    },
    {
        ordem: 13,
        nome: 'Argentina',
        engName: 'Argentina',
        sigla2: 'AR',
        sigla3: 'ARG',
        codigo: '032',
    },
    {
        ordem: 14,
        nome: 'Armênia',
        engName: 'Armenia',
        sigla2: 'AM',
        sigla3: 'ARM',
        codigo: '51',
    },
    {
        ordem: 15,
        nome: 'Aruba',
        engName: 'Aruba',
        sigla2: 'AW',
        sigla3: 'ABW',
        codigo: '533',
    },
    {
        ordem: 16,
        nome: 'Austrália',
        engName: 'Australia',
        sigla2: 'AU',
        sigla3: 'AUS',
        codigo: '036',
    },
    {
        ordem: 17,
        nome: 'Áustria',
        engName: 'Austria',
        sigla2: 'AT',
        sigla3: 'AUT',
        codigo: '040',
    },
    {
        ordem: 18,
        nome: 'Azerbaijão',
        engName: 'Azerbaijan',
        sigla2: 'AZ  ',
        sigla3: 'AZE',
        codigo: '31',
    },
    {
        ordem: 19,
        nome: 'Bahamas',
        engName: 'Bahamas',
        sigla2: 'BS',
        sigla3: 'BHS',
        codigo: '044',
    },
    {
        ordem: 20,
        nome: 'Bahrein',
        engName: 'Bahrain',
        sigla2: 'BH',
        sigla3: 'BHR',
        codigo: '048',
    },
    {
        ordem: 21,
        nome: 'Bangladesh',
        engName: 'Bangladesh',
        sigla2: 'BD',
        sigla3: 'BGD',
        codigo: '050',
    },
    {
        ordem: 22,
        nome: 'Barbados',
        engName: 'Bardados',
        sigla2: 'BB',
        sigla3: 'BRB',
        codigo: '052',
    },
    {
        ordem: 23,
        nome: 'Belarus',
        engName: 'Belarus',
        sigla2: 'BY',
        sigla3: 'BLR',
        codigo: '112',
    },
    {
        ordem: 24,
        nome: 'Bélgica',
        engName: 'Belgium',
        sigla2: 'BE',
        sigla3: 'BEL',
        codigo: '056',
    },
    {
        ordem: 25,
        nome: 'Belize',
        engName: 'Belize',
        sigla2: 'BZ',
        sigla3: 'BLZ',
        codigo: '084',
    },
    {
        ordem: 26,
        nome: 'Benin',
        engName: 'Benin',
        sigla2: 'BJ',
        sigla3: 'BEN',
        codigo: '204',
    },
    {
        ordem: 27,
        nome: 'Bermudas',
        engName: 'Bermuda',
        sigla2: 'BM',
        sigla3: 'BMU',
        codigo: '060',
    },
    {
        ordem: 28,
        nome: 'Bolívia',
        engName: 'Bolivia',
        sigla2: 'BO',
        sigla3: 'BOL',
        codigo: '068',
    },
    {
        ordem: 29,
        nome: 'Bósnia-Herzegóvina',
        engName: 'Bosnia and Herzegovina',
        sigla2: 'BA',
        sigla3: 'BIH',
        codigo: '070',
    },
    {
        ordem: 30,
        nome: 'Botsuana',
        engName: 'Botswana',
        sigla2: 'BW',
        sigla3: 'BWA',
        codigo: '072',
    },
    {
        ordem: 31,
        nome: 'Brasil',
        engName: 'Brazil',
        sigla2: 'BR',
        sigla3: 'BRA',
        codigo: '076',
    },
    {
        ordem: 32,
        nome: 'Brunei',
        engName: 'Brunei',
        sigla2: 'BN',
        sigla3: 'BRN',
        codigo: '096',
    },
    {
        ordem: 33,
        nome: 'Bulgária',
        engName: 'Bulgaria',
        sigla2: 'BG',
        sigla3: 'BGR',
        codigo: '100',
    },
    {
        ordem: 34,
        nome: 'Burkina Fasso',
        engName: 'Burkina Faso',
        sigla2: 'BF',
        sigla3: 'BFA',
        codigo: '854',
    },
    {
        ordem: 35,
        nome: 'Burundi',
        engName: 'Burundi',
        sigla2: 'BI',
        sigla3: 'BDI',
        codigo: '108',
    },
    {
        ordem: 36,
        nome: 'Butão',
        engName: 'Bhutan',
        sigla2: 'BT',
        sigla3: 'BTN',
        codigo: '064',
    },
    {
        ordem: 37,
        nome: 'Cabo Verde',
        engName: 'Cape Verde',
        sigla2: 'CV',
        sigla3: 'CPV',
        codigo: '132',
    },
    {
        ordem: 38,
        nome: 'Camarões',
        engName: 'Cameroon',
        sigla2: 'CM',
        sigla3: 'CMR',
        codigo: '120',
    },
    {
        ordem: 39,
        nome: 'Camboja',
        engName: 'Cambodia',
        sigla2: 'KH',
        sigla3: 'KHM',
        codigo: '116',
    },
    {
        ordem: 40,
        nome: 'Canadá',
        engName: 'Canada',
        sigla2: 'CA',
        sigla3: 'CAN',
        codigo: '124',
    },
    {
        ordem: 41,
        nome: 'Cazaquistão',
        engName: 'Kazakhstan',
        sigla2: 'KZ',
        sigla3: 'KAZ',
        codigo: '398',
    },
    {
        ordem: 42,
        nome: 'Chade',
        engName: 'Chad',
        sigla2: 'TD',
        sigla3: 'TCD',
        codigo: '148',
    },
    {
        ordem: 43,
        nome: 'Chile',
        engName: 'Chile',
        sigla2: 'CL',
        sigla3: 'CHL',
        codigo: '152',
    },
    {
        ordem: 44,
        nome: 'China',
        engName: 'China',
        sigla2: 'CN',
        sigla3: 'CHN',
        codigo: '156',
    },
    {
        ordem: 45,
        nome: 'Chipre',
        engName: 'Cyprus',
        sigla2: 'CY',
        sigla3: 'CYP',
        codigo: '196',
    },
    {
        ordem: 46,
        nome: 'Cingapura',
        engName: 'Singapore',
        sigla2: 'SG',
        sigla3: 'SGP',
        codigo: '702',
    },
    {
        ordem: 47,
        nome: 'Colômbia',
        engName: 'Colombia',
        sigla2: 'CO',
        sigla3: 'COL',
        codigo: '170',
    },
    {
        ordem: 48,
        nome: 'Congo',
        engName: 'Congo, Republic of',
        sigla2: 'CG',
        sigla3: 'COG',
        codigo: '178',
    },
    {
        ordem: 49,
        nome: 'Coréia do Norte',
        engName: 'Korea, North',
        sigla2: 'KP',
        sigla3: 'PRK',
        codigo: '408',
    },
    {
        ordem: 50,
        nome: 'Coréia do Sul',
        engName: 'Korea, South',
        sigla2: 'KR',
        sigla3: 'KOR',
        codigo: '410',
    },
    {
        ordem: 51,
        nome: 'Costa do Marfim',
        engName: 'Cote dIvoire',
        sigla2: 'CI',
        sigla3: 'CIV',
        codigo: '384',
    },
    {
        ordem: 52,
        nome: 'Costa Rica',
        engName: 'Costa Rica',
        sigla2: 'CR',
        sigla3: 'CRI',
        codigo: '188',
    },
    {
        ordem: 53,
        nome: 'Croácia (Hrvatska)',
        engName: 'Croatia',
        sigla2: 'HR',
        sigla3: 'HRV',
        codigo: '191',
    },
    {
        ordem: 54,
        nome: 'Cuba',
        engName: 'Cuba',
        sigla2: 'CU',
        sigla3: 'CUB',
        codigo: '192',
    },
    {
        ordem: 55,
        nome: 'Dinamarca',
        engName: 'Denmark',
        sigla2: 'DK',
        sigla3: 'DNK',
        codigo: '208',
    },
    {
        ordem: 56,
        nome: 'Djibuti',
        engName: 'Djibouti',
        sigla2: 'DJ',
        sigla3: 'DJI',
        codigo: '262',
    },
    {
        ordem: 57,
        nome: 'Dominica',
        engName: 'Dominica',
        sigla2: 'DM',
        sigla3: 'DMA',
        codigo: '212',
    },
    {
        ordem: 58,
        nome: 'Egito',
        engName: 'Egypt',
        sigla2: 'EG',
        sigla3: 'EGY',
        codigo: '818',
    },
    {
        ordem: 59,
        nome: 'El Salvador',
        engName: 'El Salvador',
        sigla2: 'SV',
        sigla3: 'SLV',
        codigo: '222',
    },
    {
        ordem: 60,
        nome: 'Emirados Árabes Unidos',
        engName: 'United Arab Emirates',
        sigla2: 'AE',
        sigla3: 'ARE',
        codigo: '784',
    },
    {
        ordem: 61,
        nome: 'Equador',
        engName: 'Ecuador',
        sigla2: 'EC',
        sigla3: 'ECU',
        codigo: '218',
    },
    {
        ordem: 62,
        nome: 'Eritréia',
        engName: 'Eritrea',
        sigla2: 'ER',
        sigla3: 'ERI',
        codigo: '232',
    },
    {
        ordem: 63,
        nome: 'Eslováquia',
        engName: 'Slovakia',
        sigla2: 'SK',
        sigla3: 'SVK',
        codigo: '703',
    },
    {
        ordem: 64,
        nome: 'Eslovênia',
        engName: 'Slovenia',
        sigla2: 'SI',
        sigla3: 'SVN',
        codigo: '705',
    },
    {
        ordem: 65,
        nome: 'Espanha',
        engName: 'Spain',
        sigla2: 'ES',
        sigla3: 'ESP',
        codigo: '724',
    },
    {
        ordem: 66,
        nome: 'Estados Unidos',
        engName: 'United States of America',
        sigla2: 'US',
        sigla3: 'USA',
        codigo: '840',
    },
    {
        ordem: 67,
        nome: 'Estônia',
        engName: 'Estonia',
        sigla2: 'EE',
        sigla3: 'EST',
        codigo: '233',
    },
    {
        ordem: 68,
        nome: 'Etiópia',
        engName: 'Ethiopia',
        sigla2: 'ET',
        sigla3: 'ETH',
        codigo: '231',
    },
    {
        ordem: 69,
        nome: 'Fiji',
        engName: 'Fiji',
        sigla2: 'FJ',
        sigla3: 'FJI',
        codigo: '242',
    },
    {
        ordem: 70,
        nome: 'Filipinas',
        engName: 'Philippines',
        sigla2: 'PH',
        sigla3: 'PHL',
        codigo: '608',
    },
    {
        ordem: 71,
        nome: 'Finlândia',
        engName: 'Finland',
        sigla2: 'FI',
        sigla3: 'FIN',
        codigo: '246',
    },
    {
        ordem: 72,
        nome: 'França',
        engName: 'France',
        sigla2: 'FR',
        sigla3: 'FRA',
        codigo: '250',
    },
    {
        ordem: 73,
        nome: 'Gabão',
        engName: 'Gabon',
        sigla2: 'GA',
        sigla3: 'GAB',
        codigo: '266',
    },
    {
        ordem: 74,
        nome: 'Gâmbia',
        engName: 'Gambia',
        sigla2: 'GM',
        sigla3: 'GMB',
        codigo: '270',
    },
    {
        ordem: 75,
        nome: 'Gana',
        engName: 'Ghana',
        sigla2: 'GH',
        sigla3: 'GHA',
        codigo: '288',
    },
    {
        ordem: 76,
        nome: 'Geórgia',
        engName: 'Georgia',
        sigla2: 'GE',
        sigla3: 'GEO',
        codigo: '268',
    },
    {
        ordem: 77,
        nome: 'Gibraltar',
        engName: 'Gibraltar',
        sigla2: 'GI',
        sigla3: 'GIB',
        codigo: '292',
    },
    {
        ordem: 78,
        nome: 'Grã-Bretanha (Reino Unido, UK)',
        engName: 'Great Britain (United Kingdom, UK)',
        sigla2: 'GB',
        sigla3: 'GBR',
        codigo: '826',
    },
    {
        ordem: 79,
        nome: 'Granada',
        engName: 'Grenada',
        sigla2: 'GD',
        sigla3: 'GRD',
        codigo: '308',
    },
    {
        ordem: 80,
        nome: 'Grécia',
        engName: 'Greece',
        sigla2: 'GR',
        sigla3: 'GRC',
        codigo: '300',
    },
    {
        ordem: 81,
        nome: 'Groelândia',
        engName: 'Greenland',
        sigla2: 'GL',
        sigla3: 'GRL',
        codigo: '304',
    },
    {
        ordem: 82,
        nome: 'Guadalupe',
        engName: 'Guadeloupe',
        sigla2: 'GP',
        sigla3: 'GLP',
        codigo: '312',
    },
    {
        ordem: 83,
        nome: 'Guam (Território dos Estados Unidos)',
        engName: 'Guam',
        sigla2: 'GU',
        sigla3: 'GUM',
        codigo: '316',
    },
    {
        ordem: 84,
        nome: 'Guatemala',
        engName: 'Guatemala',
        sigla2: 'GT',
        sigla3: 'GTM',
        codigo: '320',
    },
    {
        ordem: 85,
        nome: 'Guernsey',
        engName: 'Guernsey',
        sigla2: 'G',
        sigla3: 'GGY',
        codigo: '832',
    },
    {
        ordem: 86,
        nome: 'Guiana',
        engName: 'Guyana',
        sigla2: 'GY',
        sigla3: 'GUY',
        codigo: '328',
    },
    {
        ordem: 87,
        nome: 'Guiana Francesa',
        engName: 'French Guiana',
        sigla2: 'GF',
        sigla3: 'GUF',
        codigo: '254',
    },
    {
        ordem: 88,
        nome: 'Guiné',
        engName: 'Guinea',
        sigla2: 'GN',
        sigla3: 'GIN',
        codigo: '324',
    },
    {
        ordem: 89,
        nome: 'Guiné Equatorial',
        engName: 'Equatorial Guinea',
        sigla2: 'GQ',
        sigla3: 'GNQ',
        codigo: '226',
    },
    {
        ordem: 90,
        nome: 'Guiné-Bissau',
        engName: 'Guinea-Bissau',
        sigla2: 'GW',
        sigla3: 'GNB',
        codigo: '624',
    },
    {
        ordem: 91,
        nome: 'Haiti',
        engName: 'Haiti',
        sigla2: 'HT',
        sigla3: 'HTI',
        codigo: '332',
    },
    {
        ordem: 92,
        nome: 'Holanda',
        engName: 'Netherlands',
        sigla2: 'NL',
        sigla3: 'NLD',
        codigo: '528',
    },
    {
        ordem: 93,
        nome: 'Honduras',
        engName: 'Honduras',
        sigla2: 'HN',
        sigla3: 'HND',
        codigo: '340',
    },
    {
        ordem: 94,
        nome: 'Hong Kong',
        engName: 'Hong Kong',
        sigla2: 'HK',
        sigla3: 'HKG',
        codigo: '344',
    },
    {
        ordem: 95,
        nome: 'Hungria',
        engName: 'Hungary',
        sigla2: 'HU',
        sigla3: 'HUN',
        codigo: '348',
    },
    {
        ordem: 96,
        nome: 'Iêmen',
        engName: 'Yemen',
        sigla2: 'YE',
        sigla3: 'YEM',
        codigo: '887',
    },
    {
        ordem: 97,
        nome: 'Ilha Bouvet (Território da Noruega)',
        engName: 'Bouvet Island',
        sigla2: 'BV',
        sigla3: 'BVT',
        codigo: '074',
    },
    {
        ordem: 98,
        nome: 'Ilha do Homem',
        engName: 'Isle of man',
        sigla2: 'IM',
        sigla3: 'IMN',
        codigo: '833',
    },
    {
        ordem: 99,
        nome: 'Ilha Natal',
        engName: 'Christmas Island',
        sigla2: 'CX',
        sigla3: 'CXR',
        codigo: '162',
    },
    {
        ordem: 100,
        nome: 'Ilha Pitcairn',
        engName: 'Pitcarin Islands',
        sigla2: 'PN',
        sigla3: 'PCN',
        codigo: '612',
    },
    {
        ordem: 101,
        nome: 'Ilha Reunião',
        engName: 'Reunion Island',
        sigla2: 'RE',
        sigla3: 'REU',
        codigo: '638',
    },
    {
        ordem: 102,
        nome: 'Ilhas Aland',
        engName: 'Aland Islands',
        sigla2: 'AX',
        sigla3: 'ALA',
        codigo: '248',
    },
    {
        ordem: 103,
        nome: 'Ilhas Cayman',
        engName: 'Cayman Islands',
        sigla2: 'KY',
        sigla3: 'CYM',
        codigo: '136',
    },
    {
        ordem: 104,
        nome: 'Ilhas Cocos',
        engName: 'Cocos Islands',
        sigla2: 'CC',
        sigla3: 'CCK',
        codigo: '166',
    },
    {
        ordem: 105,
        nome: 'Ilhas Comores',
        engName: 'Comoros',
        sigla2: 'KM',
        sigla3: 'COM',
        codigo: '174',
    },
    {
        ordem: 106,
        nome: 'Ilhas Cook',
        engName: 'Cook Islands',
        sigla2: 'CK',
        sigla3: 'COK',
        codigo: '184',
    },
    {
        ordem: 107,
        nome: 'Ilhas Faroes',
        engName: 'Faroe Islands',
        sigla2: 'FO',
        sigla3: 'FRO',
        codigo: '234',
    },
    {
        ordem: 108,
        nome: 'Ilhas Falkland (Malvinas)',
        engName: 'Falkland Islands',
        sigla2: 'FK',
        sigla3: 'FLK',
        codigo: '238',
    },
    {
        ordem: 109,
        nome: 'Ilhas Geórgia do Sul e Sandwich do Sul',
        engName: 'South Georgia aond the South Sandwich Islands',
        sigla2: 'GS',
        sigla3: 'SGS',
        codigo: '239',
    },
    {
        ordem: 110,
        nome: 'Ilhas Heard e McDonald (Território da Austrália)',
        engName: 'Heard Island and Mcdonald Islands',
        sigla2: 'HM',
        sigla3: 'HMD',
        codigo: '334',
    },
    {
        ordem: 111,
        nome: 'Ilhas Marianas do Norte',
        engName: 'Northern Mariana Islands',
        sigla2: 'MP',
        sigla3: 'MNP',
        codigo: '580',
    },
    {
        ordem: 112,
        nome: 'Ilhas Marshall',
        engName: 'Marshall Islands',
        sigla2: 'MH',
        sigla3: 'MHL',
        codigo: '584',
    },
    {
        ordem: 113,
        nome: 'Ilhas Menores dos Estados Unidos',
        engName: 'United States Minor Outlying Islands',
        sigla2: 'UM',
        sigla3: 'UMI',
        codigo: '581',
    },
    {
        ordem: 114,
        nome: 'Ilhas Norfolk',
        engName: 'Norfolk Islands',
        sigla2: 'NF',
        sigla3: 'NFK',
        codigo: '574',
    },
    {
        ordem: 115,
        nome: 'Ilhas Seychelles',
        engName: 'Seychalles Islands',
        sigla2: 'SC',
        sigla3: 'SYC',
        codigo: '690',
    },
    {
        ordem: 116,
        nome: 'Ilhas Solomão',
        engName: 'Solomon Islands',
        sigla2: 'SB',
        sigla3: 'SLB',
        codigo: '090',
    },
    {
        ordem: 117,
        nome: 'Ilhas Svalbard e Jan Mayen',
        engName: 'Svalbard and Jan Mayen Islands',
        sigla2: 'SJ',
        sigla3: 'SJM',
        codigo: '744',
    },
    {
        ordem: 118,
        nome: 'Ilhas Tokelau',
        engName: 'Tokelau Islands',
        sigla2: 'TK',
        sigla3: 'TKL',
        codigo: '772',
    },
    {
        ordem: 119,
        nome: 'Ilhas Turks e Caicos',
        engName: 'Turks and Caicos Islands',
        sigla2: 'TC',
        sigla3: 'TCA',
        codigo: '796',
    },
    {
        ordem: 120,
        nome: 'Ilhas Virgens (Estados Unidos)',
        engName: 'United States Virgin Islands',
        sigla2: 'VI',
        sigla3: 'VIR',
        codigo: '850',
    },
    {
        ordem: 121,
        nome: 'Ilhas Virgens (Inglaterra)',
        engName: 'British Virgin Islands (England)',
        sigla2: 'VG',
        sigla3: 'VGB',
        codigo: '092',
    },
    {
        ordem: 122,
        nome: 'Ilhas Wallis e Futuna',
        engName: 'Wallis and Futuna Islands',
        sigla2: 'WF',
        sigla3: 'WLF',
        codigo: '876',
    },
    {
        ordem: 123,
        nome: 'índia',
        engName: 'India',
        sigla2: 'IN',
        sigla3: 'IND',
        codigo: '356',
    },
    {
        ordem: 124,
        nome: 'Indonésia',
        engName: 'Indonesia',
        sigla2: 'ID',
        sigla3: 'IDN',
        codigo: '360',
    },
    {
        ordem: 125,
        nome: 'Irã',
        engName: 'Iran',
        sigla2: 'IR',
        sigla3: 'IRN',
        codigo: '364',
    },
    {
        ordem: 126,
        nome: 'Iraque',
        engName: 'Iraq',
        sigla2: 'IQ',
        sigla3: 'IRQ',
        codigo: '368',
    },
    {
        ordem: 127,
        nome: 'Irlanda',
        engName: 'Ireland',
        sigla2: 'IE',
        sigla3: 'IRL',
        codigo: '372',
    },
    {
        ordem: 128,
        nome: 'Islândia',
        engName: 'Iceland',
        sigla2: 'IS',
        sigla3: 'ISL',
        codigo: '352',
    },
    {
        ordem: 129,
        nome: 'Israel',
        engName: 'Israel',
        sigla2: 'IL',
        sigla3: 'ISR',
        codigo: '376',
    },
    {
        ordem: 130,
        nome: 'Itália',
        engName: 'Italy',
        sigla2: 'IT',
        sigla3: 'ITA',
        codigo: '380',
    },
    {
        ordem: 131,
        nome: 'Jamaica',
        engName: 'Jamaica',
        sigla2: 'JM',
        sigla3: 'JAM',
        codigo: '388',
    },
    {
        ordem: 132,
        nome: 'Japão',
        engName: 'Japan',
        sigla2: 'JP',
        sigla3: 'JPN',
        codigo: '392',
    },
    {
        ordem: 133,
        nome: 'Jersey',
        engName: 'Jersey',
        sigla2: 'JE',
        sigla3: 'JEY',
        codigo: '832',
    },
    {
        ordem: 134,
        nome: 'Jordânia',
        engName: 'Jordan',
        sigla2: 'JO',
        sigla3: 'JOR',
        codigo: '400',
    },
    {
        ordem: 135,
        nome: 'Kênia',
        engName: 'Kenya',
        sigla2: 'KE',
        sigla3: 'KEN',
        codigo: '404',
    },
    {
        ordem: 136,
        nome: 'Kiribati',
        engName: 'Kiribati',
        sigla2: 'KI',
        sigla3: 'KIR',
        codigo: '296',
    },
    {
        ordem: 137,
        nome: 'Kuait',
        engName: 'Kuwait',
        sigla2: 'KW',
        sigla3: 'KWT',
        codigo: '414',
    },
    {
        ordem: 138,
        nome: 'Laos',
        engName: 'Laos',
        sigla2: 'LA',
        sigla3: 'LAO',
        codigo: '418',
    },
    {
        ordem: 139,
        nome: 'Látvia',
        engName: 'Latvia',
        sigla2: 'LV',
        sigla3: 'LVA',
        codigo: '428',
    },
    {
        ordem: 140,
        nome: 'Lesoto',
        engName: 'Lesotho',
        sigla2: 'LS',
        sigla3: 'LSO',
        codigo: '426',
    },
    {
        ordem: 141,
        nome: 'Líbano',
        engName: 'Lebanon',
        sigla2: 'LB',
        sigla3: 'LBN',
        codigo: '422',
    },
    {
        ordem: 142,
        nome: 'Libéria',
        engName: 'Liberia',
        sigla2: 'LR',
        sigla3: 'LBR',
        codigo: '430',
    },
    {
        ordem: 143,
        nome: 'Líbia',
        engName: 'Libya',
        sigla2: 'LY',
        sigla3: 'LBY',
        codigo: '434',
    },
    {
        ordem: 144,
        nome: 'Liechtenstein',
        engName: 'Liechtenstein',
        sigla2: 'LI',
        sigla3: 'LIE',
        codigo: '438',
    },
    {
        ordem: 145,
        nome: 'Lituânia',
        engName: 'Lithuania',
        sigla2: 'LT',
        sigla3: 'LTU',
        codigo: '440',
    },
    {
        ordem: 146,
        nome: 'Luxemburgo',
        engName: 'Luxembourg',
        sigla2: 'LU',
        sigla3: 'LUX',
        codigo: '442',
    },
    {
        ordem: 147,
        nome: 'Macau',
        engName: 'Macao',
        sigla2: 'MO',
        sigla3: 'MAC',
        codigo: '446',
    },
    {
        ordem: 148,
        nome: 'Macedônia (República Yugoslava)',
        engName: 'Macadonia',
        sigla2: 'MK',
        sigla3: 'MKD',
        codigo: '807',
    },
    {
        ordem: 149,
        nome: 'Madagascar',
        engName: 'Madagascar',
        sigla2: 'MG',
        sigla3: 'MDG',
        codigo: '450',
    },
    {
        ordem: 150,
        nome: 'Malásia',
        engName: 'Malaysia',
        sigla2: 'MY',
        sigla3: 'MYS',
        codigo: '458',
    },
    {
        ordem: 151,
        nome: 'Malaui',
        engName: 'Malawi',
        sigla2: 'MW',
        sigla3: 'MWI',
        codigo: '454',
    },
    {
        ordem: 152,
        nome: 'Maldivas',
        engName: 'Maldives',
        sigla2: 'MV',
        sigla3: 'MDV',
        codigo: '462',
    },
    {
        ordem: 153,
        nome: 'Mali',
        engName: 'Mali',
        sigla2: 'ML',
        sigla3: 'MLI',
        codigo: '466',
    },
    {
        ordem: 154,
        nome: 'Malta',
        engName: 'Malta',
        sigla2: 'MT',
        sigla3: 'MLT',
        codigo: '470',
    },
    {
        ordem: 155,
        nome: 'Marrocos',
        engName: 'Morocco',
        sigla2: 'MA',
        sigla3: 'MAR',
        codigo: '504',
    },
    {
        ordem: 156,
        nome: 'Martinica',
        engName: 'Martinique',
        sigla2: 'MQ',
        sigla3: 'MTQ',
        codigo: '474',
    },
    {
        ordem: 157,
        nome: 'Maurício',
        engName: 'Mauritius',
        sigla2: 'MU',
        sigla3: 'MUS',
        codigo: '480',
    },
    {
        ordem: 158,
        nome: 'Mauritânia',
        engName: 'Mauritania',
        sigla2: 'MR',
        sigla3: 'MRT',
        codigo: '478',
    },
    {
        ordem: 159,
        nome: 'Mayotte',
        engName: 'Mayotte',
        sigla2: 'YT',
        sigla3: 'MYT',
        codigo: '175',
    },
    {
        ordem: 160,
        nome: 'México',
        engName: 'Mexico',
        sigla2: 'MX',
        sigla3: 'MEX',
        codigo: '484',
    },
    {
        ordem: 161,
        nome: 'Micronésia',
        engName: 'Micronesia',
        sigla2: 'FM',
        sigla3: 'FSM',
        codigo: '583',
    },
    {
        ordem: 162,
        nome: 'Moçambique',
        engName: 'Mozambique',
        sigla2: 'MZ',
        sigla3: 'MOZ',
        codigo: '508',
    },
    {
        ordem: 163,
        nome: 'Moldova',
        engName: 'Moldova',
        sigla2: 'MD',
        sigla3: 'MDA',
        codigo: '498',
    },
    {
        ordem: 164,
        nome: 'Mônaco',
        engName: 'Monaco',
        sigla2: 'MC',
        sigla3: 'MCO',
        codigo: '492',
    },
    {
        ordem: 165,
        nome: 'Mongólia',
        engName: 'Mongolia',
        sigla2: 'MN',
        sigla3: 'MNG',
        codigo: '496',
    },
    {
        ordem: 166,
        nome: 'Montenegro',
        engName: 'Montenegro',
        sigla2: 'ME',
        sigla3: 'MNE',
        codigo: '499',
    },
    {
        ordem: 167,
        nome: 'Montserrat',
        engName: 'Montserrat',
        sigla2: 'MS',
        sigla3: 'MSR',
        codigo: '500',
    },
    {
        ordem: 168,
        nome: 'Myanma',
        engName: 'Myanmar',
        sigla2: 'MM',
        sigla3: 'MMR',
        codigo: '104',
    },
    {
        ordem: 169,
        nome: 'Namíbia',
        engName: 'Namibia',
        sigla2: 'NA',
        sigla3: 'NAM',
        codigo: '516',
    },
    {
        ordem: 170,
        nome: 'Nauru',
        engName: 'Nauru',
        sigla2: 'NR',
        sigla3: 'NRU',
        codigo: '520',
    },
    {
        ordem: 171,
        nome: 'Nepal',
        engName: 'Nepal',
        sigla2: 'NP',
        sigla3: 'NPL',
        codigo: '524',
    },
    {
        ordem: 172,
        nome: 'Nicarágua',
        engName: 'Nicaragua',
        sigla2: 'NI',
        sigla3: 'NIC',
        codigo: '558',
    },
    {
        ordem: 173,
        nome: 'Níger',
        engName: 'Niger',
        sigla2: 'NE',
        sigla3: 'NER',
        codigo: '562',
    },
    {
        ordem: 174,
        nome: 'Nigéria',
        engName: 'Nigeria',
        sigla2: 'NG',
        sigla3: 'NGA',
        codigo: '566',
    },
    {
        ordem: 175,
        nome: 'Niue',
        engName: 'Niue',
        sigla2: 'NU',
        sigla3: 'NIU',
        codigo: '570',
    },
    {
        ordem: 176,
        nome: 'Noruega',
        engName: 'Norway',
        sigla2: 'NO',
        sigla3: 'NOR',
        codigo: '578',
    },
    {
        ordem: 177,
        nome: 'Nova Caledônia',
        engName: 'New Caledonia',
        sigla2: 'NC',
        sigla3: 'NCL',
        codigo: '540',
    },
    {
        ordem: 178,
        nome: 'Nova Zelândia',
        engName: 'New Zealand',
        sigla2: 'NZ',
        sigla3: 'NZL',
        codigo: '554',
    },
    {
        ordem: 179,
        nome: 'Omã',
        engName: 'Oman',
        sigla2: 'OM',
        sigla3: 'OMN',
        codigo: '512',
    },
    {
        ordem: 180,
        nome: 'Palau',
        engName: 'Palau',
        sigla2: 'PW',
        sigla3: 'PLW',
        codigo: '585',
    },
    {
        ordem: 181,
        nome: 'Panamá',
        engName: 'Panama',
        sigla2: 'PA',
        sigla3: 'PAN',
        codigo: '591',
    },
    {
        ordem: 182,
        nome: 'Papua-Nova Guiné',
        engName: 'Papua New Guinea',
        sigla2: 'PG',
        sigla3: 'PNG',
        codigo: '598',
    },
    {
        ordem: 183,
        nome: 'Paquistão',
        engName: 'Pakistan',
        sigla2: 'PK',
        sigla3: 'PAK',
        codigo: '586',
    },
    {
        ordem: 184,
        nome: 'Paraguai',
        engName: 'Paraguay',
        sigla2: 'PY',
        sigla3: 'PRY',
        codigo: '600',
    },
    {
        ordem: 185,
        nome: 'Peru',
        engName: 'Peru',
        sigla2: 'PE',
        sigla3: 'PER',
        codigo: '604',
    },
    {
        ordem: 186,
        nome: 'Polinésia Francesa',
        engName: 'French Polynesia',
        sigla2: 'PF',
        sigla3: 'PYF',
        codigo: '258',
    },
    {
        ordem: 187,
        nome: 'Polônia',
        engName: 'Poland',
        sigla2: 'PL',
        sigla3: 'POL',
        codigo: '616',
    },
    {
        ordem: 188,
        nome: 'Porto Rico',
        engName: 'Puerto Rico',
        sigla2: 'PR',
        sigla3: 'PRI',
        codigo: '630',
    },
    {
        ordem: 189,
        nome: 'Portugal',
        engName: 'Portugal',
        sigla2: 'PT',
        sigla3: 'PRT',
        codigo: '620',
    },
    {
        ordem: 190,
        nome: 'Qatar',
        engName: 'Qatar',
        sigla2: 'QA',
        sigla3: 'QAT',
        codigo: '634',
    },
    {
        ordem: 191,
        nome: 'Quirguistão',
        engName: 'Kyrgyzstan',
        sigla2: 'KG',
        sigla3: 'KGZ',
        codigo: '417',
    },
    {
        ordem: 192,
        nome: 'República Centro-Africana',
        engName: 'Central African Republic',
        sigla2: 'CF',
        sigla3: 'CAF',
        codigo: '140',
    },
    {
        ordem: 193,
        nome: 'República Democrática do Congo',
        engName: 'Congo, Democratic Republic of The',
        sigla2: 'CD',
        sigla3: 'COD',
        codigo: '180',
    },
    {
        ordem: 194,
        nome: 'República Dominicana',
        engName: 'Dominican Republic',
        sigla2: 'DO',
        sigla3: 'DOM',
        codigo: '214',
    },
    {
        ordem: 195,
        nome: 'República Tcheca',
        engName: 'Czech Republic',
        sigla2: 'CZ',
        sigla3: 'CZE',
        codigo: '203',
    },
    {
        ordem: 196,
        nome: 'Romênia',
        engName: 'Romania',
        sigla2: 'RO',
        sigla3: 'ROM',
        codigo: '642',
    },
    {
        ordem: 197,
        nome: 'Ruanda',
        engName: 'Rwanda',
        sigla2: 'RW',
        sigla3: 'RWA',
        codigo: '646',
    },
    {
        ordem: 198,
        nome: 'Rússia (antiga URSS) - Federação Russa',
        engName: 'Russia',
        sigla2: 'RU',
        sigla3: 'RUS',
        codigo: '643',
    },
    {
        ordem: 199,
        nome: 'Saara Ocidental',
        engName: 'Western Sahara',
        sigla2: 'EH',
        sigla3: 'ESH',
        codigo: '732',
    },
    {
        ordem: 200,
        nome: 'Saint Vincente e Granadinas',
        engName: 'Saint Vincent and the Grenadines',
        sigla2: 'VC',
        sigla3: 'VCT',
        codigo: '670',
    },
    {
        ordem: 201,
        nome: 'Samoa Americana',
        engName: 'American Samoa',
        sigla2: 'AS',
        sigla3: 'ASM',
        codigo: '016',
    },
    {
        ordem: 202,
        nome: 'Samoa Ocidental',
        engName: 'Samoa',
        sigla2: 'WS',
        sigla3: 'WSM',
        codigo: '882',
    },
    {
        ordem: 203,
        nome: 'San Marino',
        engName: 'San Mario',
        sigla2: 'SM',
        sigla3: 'SMR',
        codigo: '674',
    },
    {
        ordem: 204,
        nome: 'Santa Helena',
        engName: 'Saint Helena',
        sigla2: 'SH',
        sigla3: 'SHN',
        codigo: '654',
    },
    {
        ordem: 205,
        nome: 'Santa Lúcia',
        engName: 'Saint Lucia',
        sigla2: 'LC',
        sigla3: 'LCA',
        codigo: '662',
    },
    {
        ordem: 206,
        nome: 'São Bartolomeu',
        engName: 'Saint Barthélemy',
        sigla2: 'BL',
        sigla3: 'BLM',
        codigo: '652',
    },
    {
        ordem: 207,
        nome: 'São Cristóvão e Névis',
        engName: 'Saint Kitts and Nevis',
        sigla2: 'KN',
        sigla3: 'KNA',
        codigo: '659',
    },
    {
        ordem: 208,
        nome: 'São Martim',
        engName: 'Saint Martin',
        sigla2: 'MF',
        sigla3: 'MAF',
        codigo: '663',
    },
    {
        ordem: 209,
        nome: 'São Tomé e Príncipe',
        engName: 'Sao Tome and Principe',
        sigla2: 'ST',
        sigla3: 'STP',
        codigo: '678',
    },
    {
        ordem: 210,
        nome: 'Senegal',
        engName: 'Senegal',
        sigla2: 'SN',
        sigla3: 'SEN',
        codigo: '686',
    },
    {
        ordem: 211,
        nome: 'Serra Leoa',
        engName: 'Sierra Leone',
        sigla2: 'SL',
        sigla3: 'SLE',
        codigo: '694',
    },
    {
        ordem: 212,
        nome: 'Sérvia',
        engName: 'Serbia',
        sigla2: 'RS',
        sigla3: 'SRB',
        codigo: '688',
    },
    {
        ordem: 213,
        nome: 'Síria',
        engName: 'Syria',
        sigla2: 'SY',
        sigla3: 'SYR',
        codigo: '760',
    },
    {
        ordem: 214,
        nome: 'Somália',
        engName: 'Somalia',
        sigla2: 'SO',
        sigla3: 'SOM',
        codigo: '706',
    },
    {
        ordem: 215,
        nome: 'Sri Lanka',
        engName: 'Sri Lanka',
        sigla2: 'LK',
        sigla3: 'LKA',
        codigo: '144',
    },
    {
        ordem: 216,
        nome: 'St. Pierre and Miquelon',
        engName: 'St. Pierre and Miquelon',
        sigla2: 'PM',
        sigla3: 'SPM',
        codigo: '666',
    },
    {
        ordem: 217,
        nome: 'Suazilândia',
        engName: 'Swaziland',
        sigla2: 'SZ',
        sigla3: 'SWZ',
        codigo: '748',
    },
    {
        ordem: 218,
        nome: 'Sudão',
        engName: 'Sudan',
        sigla2: 'SD',
        sigla3: 'SDN',
        codigo: '736',
    },
    {
        ordem: 219,
        nome: 'Suécia',
        engName: 'Sweden',
        sigla2: 'SE',
        sigla3: 'SWE',
        codigo: '752',
    },
    {
        ordem: 220,
        nome: 'Suíça',
        engName: 'Switzerland',
        sigla2: 'CH',
        sigla3: 'CHE',
        codigo: '756',
    },
    {
        ordem: 221,
        nome: 'Suriname',
        engName: 'Suriname',
        sigla2: 'SR',
        sigla3: 'SUR',
        codigo: '740',
    },
    {
        ordem: 222,
        nome: 'Tadjiquistão',
        engName: 'Tajikistan',
        sigla2: 'TJ',
        sigla3: 'TJK',
        codigo: '762',
    },
    {
        ordem: 223,
        nome: 'Tailândia',
        engName: 'Thailand',
        sigla2: 'TH',
        sigla3: 'THA',
        codigo: '764',
    },
    {
        ordem: 224,
        nome: 'Taiwan',
        engName: 'Taiwan',
        sigla2: 'TW',
        sigla3: 'TWN',
        codigo: '158',
    },
    {
        ordem: 225,
        nome: 'Tanzânia',
        engName: 'Tanzania',
        sigla2: 'TZ',
        sigla3: 'TZA',
        codigo: '834',
    },
    {
        ordem: 226,
        nome: 'Território Britânico do Oceano índico',
        engName: 'British Indian Ocean Territory',
        sigla2: 'IO',
        sigla3: 'IOT',
        codigo: '086',
    },
    {
        ordem: 227,
        nome: 'Territórios do Sul da França',
        engName: 'Southern French Territories',
        sigla2: 'TF',
        sigla3: 'ATF',
        codigo: '260',
    },
    {
        ordem: 228,
        nome: 'Territórios Palestinos Ocupados',
        engName: 'Occupied Palestinian Territories',
        sigla2: 'PS',
        sigla3: 'PSE',
        codigo: '275',
    },
    {
        ordem: 229,
        nome: 'Timor Leste',
        engName: 'East Timor',
        sigla2: 'TP',
        sigla3: 'TMP',
        codigo: '626',
    },
    {
        ordem: 230,
        nome: 'Togo',
        engName: 'Togo',
        sigla2: 'TG',
        sigla3: 'TGO',
        codigo: '768',
    },
    {
        ordem: 231,
        nome: 'Tonga',
        engName: 'Tonga',
        sigla2: 'TO',
        sigla3: 'TON',
        codigo: '776',
    },
    {
        ordem: 232,
        nome: 'Trinidad and Tobago',
        engName: 'Trinidad and Tabago',
        sigla2: 'TT',
        sigla3: 'TTO',
        codigo: '780',
    },
    {
        ordem: 233,
        nome: 'Tunísia',
        engName: 'Tunisia',
        sigla2: 'TN',
        sigla3: 'TUN',
        codigo: '788',
    },
    {
        ordem: 234,
        nome: 'Turcomenistão',
        engName: 'Turkmenistan',
        sigla2: 'TM',
        sigla3: 'TKM',
        codigo: '795',
    },
    {
        ordem: 235,
        nome: 'Turquia',
        engName: 'Turkey',
        sigla2: 'TR',
        sigla3: 'TUR',
        codigo: '792',
    },
    {
        ordem: 236,
        nome: 'Tuvalu',
        engName: 'Tuvalu',
        sigla2: 'TV',
        sigla3: 'TUV',
        codigo: '798',
    },
    {
        ordem: 237,
        nome: 'Ucrânia',
        engName: 'Ukraine',
        sigla2: 'UA',
        sigla3: 'UKR',
        codigo: '804',
    },
    {
        ordem: 238,
        nome: 'Uganda',
        engName: 'Uganda',
        sigla2: 'UG',
        sigla3: 'UGA',
        codigo: '800',
    },
    {
        ordem: 239,
        nome: 'Uruguai',
        engName: 'Uruguay',
        sigla2: 'UY',
        sigla3: 'URY',
        codigo: '858',
    },
    {
        ordem: 240,
        nome: 'Uzbequistão',
        engName: 'Uzbekistan',
        sigla2: 'UZ',
        sigla3: 'UZB',
        codigo: '860',
    },
    {
        ordem: 241,
        nome: 'Vanuatu',
        engName: 'Vanuatu',
        sigla2: 'VU',
        sigla3: 'VUT',
        codigo: '548',
    },
    {
        ordem: 242,
        nome: 'Vaticano',
        engName: 'Vatican City',
        sigla2: 'VA',
        sigla3: 'VAT',
        codigo: '336',
    },
    {
        ordem: 243,
        nome: 'Venezuela',
        engName: 'Venezuela',
        sigla2: 'VE',
        sigla3: 'VEN',
        codigo: '862',
    },
    {
        ordem: 244,
        nome: 'Vietnã',
        engName: 'Vietnam',
        sigla2: 'VN',
        sigla3: 'VNM',
        codigo: '704',
    },
    {
        ordem: 245,
        nome: 'Zâmbia',
        engName: 'Zambia',
        sigla2: 'ZM',
        sigla3: 'ZMB',
        codigo: '894',
    },
    {
        ordem: 246,
        nome: 'Zimbábue',
        engName: 'Zimbabue',
        sigla2: 'ZW',
        sigla3: 'ZWE',
        codigo: '716',
    },
];
