import { SET_BUILDING_NAME, SET_SPECIFIC_VALUE, SET_ACTIVE_BUILD } from '../actiontypes/suitesReserve';

export const setSpecificValue = (key, value) => ({
  type: SET_SPECIFIC_VALUE,
  payload: {
    key,
    value,
  },
});

export const setBuildingName = value => ({
  type: SET_BUILDING_NAME,
  payload: {
    value,
  },
});

export const setActiveBuild = value => ({
  type: SET_ACTIVE_BUILD,
  payload: {
    value,
  },
});
