// React Imports
import React, { useEffect, useState } from 'react';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import './EmailConfirmation.scss';
import { EmailConfirmationCodes } from '../../assets/constants/response_codes';
import { routesPaths } from '../../routes/RoutesPaths';
import { emailConfirmation } from '../../dataSource/auth_requests';
import AccessWrapper from '../accessPages/accessWrapper/AccessWrapper';
import { EmailConfirmationMessages } from '../../assets/strings/strings';

// Components

const EmailConfirmationComponent = () => {

  const initialMessage = (
    <div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  );

  const history = useHistory();
  const [message, setMessage] = useState(initialMessage);
  const { token } = useRouteMatch().params;

  useEffect(() => {
    async function sendEmailConfirmation() {
      const response = await emailConfirmation({ token });

      switch (response.code) {
        case EmailConfirmationCodes.SUCCESS:
          setMessage(
            <div>
              <p>
                <strong>
                  {EmailConfirmationMessages.emailConfirmedSuccessfully}
                </strong>
              </p>
              <p>{EmailConfirmationMessages.loginNow}</p>
            </div>,
          );

          break;

        case EmailConfirmationCodes.error.EMAIL_TOKEN_NOT_FOUND:
        case EmailConfirmationCodes.error.INVALID_EMAIL_TOKEN:
          setMessage(
            <div>
              <p>
                <strong>{EmailConfirmationMessages.invalidToken}</strong>
              </p>
            </div>,
          );
          break;

        default:
          break;
      }
    }
    document.title = '360 Suítes - Confirmação de Email';
    sendEmailConfirmation();
  }, []);

  return (
    <div className="EmailConfirmation">
      <AccessWrapper showTerms={false}>
        <div className="message">{message}</div>
        <Button
          type="primary"
          onClick={() => history.push(routesPaths.login)}
        >
          {EmailConfirmationMessages.doLogin}
        </Button>
      </AccessWrapper>
    </div>
  );
};

EmailConfirmationComponent.propTypes = {};

EmailConfirmationComponent.defaultProps = {};

export default EmailConfirmationComponent;
