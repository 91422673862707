export const roundTwoDecimals = num => Math.round((num + Number.EPSILON) * 100) / 100;

export const getFormattedDouble = d =>
  Number(Number.parseFloat(Math.round(d * 100) / 100)).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const getOnlyNumbers = value => value.replace(/\D/g, '');

export const formatCPF = cpf => {
  const numbers = cpf.replace(/\D/g, ''); // Only numbers
  if (numbers.length === 0) {
    return '';
  }
  if (numbers.length >= 1 && numbers.length <= 3) {
    return `${numbers.slice(0, 3)}`;
  }
  if (numbers.length >= 4 && numbers.length <= 6) {
    return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}`;
  }
  if (numbers.length >= 7 && numbers.length <= 9) {
    return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(6, 9)}`;
  }
  return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(6, 9)}-${numbers.slice(9, 11)}`;
};

// output: XX.XXX.XXX/YYYY-ZZ
export const formatCNPJ = cnpj => {
  const numbers = cnpj.replace(/\D/g, ''); // Only numbers
  if (numbers.length === 0) {
    return '';
  }
  if (numbers.length >= 1 && numbers.length <= 2) {
    return `${numbers.slice(0, 2)}`;
  }
  if (numbers.length >= 3 && numbers.length <= 5) {
    return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}`;
  }
  if (numbers.length >= 6 && numbers.length <= 8) {
    return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5, 8)}`;
  }
  if (numbers.length >= 9 && numbers.length <= 12) {
    return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5, 8)}/${numbers.slice(8, 12)}`;
  }
  return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5, 8)}/${numbers.slice(8, 12)}-${numbers.slice(
    12,
    14,
  )}`;
};

export const formatCEP = cnpj => {
  const numbers = cnpj.replace(/\D/g, ''); // Only numbers
  if (numbers.length === 0) {
    return '';
  }
  if (numbers.length >= 1 && numbers.length <= 5) {
    return `${numbers.slice(0, 5)}`;
  }
  return `${numbers.slice(0, 5)}-${numbers.slice(5, 8)}`;
};

export const formatTelephone = rawValue => {
  const value = rawValue.replace(/\D/g, ''); // Only numbers
  if (value.length === 0) {
    return '';
  }
  if (value.length === 1 || value.length === 2) {
    return `(${value.slice(0, 2)}`;
  }
  if (value.length >= 3 && value.length <= 6) {
    return `(${value.slice(0, 2)}) ${value.slice(2, 6)}`;
  }
  if (value.length <= 10) {
    return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`;
  }
  return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
};

export const formatCellphone = rawValue => {
  const value = rawValue.replace(/\D/g, ''); // Only numbers
  if (value.length === 0) {
    return '';
  }
  if (value.length === 1 || value.length === 2) {
    return `(${value.slice(0, 2)}`;
  }
  if (value.length >= 3 && value.length <= 7) {
    return `(${value.slice(0, 2)}) ${value.slice(2, 7)}`;
  }
  return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const strValuePtBrToNumber = valueStrPtBr => {
  const newValueStr = valueStrPtBr.replace('.', '').replace(',', '.');
  return Number(newValueStr);
};

export const onlyFirstAndLastName = fullName => {
  const arrayNames = fullName.split(' ');
  const firstName = arrayNames[0];
  const lastName = arrayNames[arrayNames.length - 1];
  return `${firstName} ${lastName} `;
};

export const formatDateWithoutZero = text => {
  const formatedDate = text.split('-');

  formatedDate.map((value, index) => {
    if (value.length === 1) {
      formatedDate[index] = zeroPad(value, value.length + 1);
    }
  });

  const dateStringConverted = `${formatedDate[0]}-${formatedDate[1]}-${formatedDate[2]}`;

  return dateStringConverted;
};
