import jwtDecode from 'jwt-decode';
import { routesPaths } from '../routes/RoutesPaths';
import { BASIC_PERMISSION } from '../assets/constants/permissions_feature';
import { encrypty, decrypty } from './helpers';
import { DEFAULT_LANGUAGE } from '../assets/constants/constants';

// TODO: [XP360-619] Transpilar o arquivo USERS.JS para TS
export const keyVariables = {
  userToken: 'user_token',
  userPermissions: 'user_permissions',
  userType: 'user_type',
  userId: 'user_id',
  userData: 'user_data',
  currentLanguage: 'current_language',
  lastPageBeforeLogin: 'last_page_before_login',
  paymentGuestValues: 'XP360:guestValues',
};

export const removeUserFromStorage = () => {
  localStorage.removeItem(keyVariables.userToken);
  localStorage.removeItem(keyVariables.userPermissions);
  localStorage.removeItem(keyVariables.userType);
  localStorage.removeItem(keyVariables.userId);
  localStorage.removeItem(keyVariables.paymentGuestValues);
};

export const setStorageValue = (value, key) => {
  try {
    return localStorage.setItem(key, encrypty(value));
  } catch (error) {
    console.log(error);
    removeUserFromStorage();
    this.props.history.push(routesPaths.root);

    return false;
  }
};

export const getStorageValue = key => {
  try {
    return decrypty(localStorage.getItem(key));
  } catch (error) {
    // Logout
    console.log(error);
    removeUserFromStorage();

    return false;
  }
};

export const getCurrentLanguage = () => {
  try {
    return decrypty(localStorage.getItem(keyVariables.currentLanguage)) || DEFAULT_LANGUAGE;
  } catch (error) {
    return DEFAULT_LANGUAGE;
  }
};

export const getAccessToken = () => getStorageValue(keyVariables.userToken);

export const getUserPermissions = () => JSON.parse(getStorageValue(keyVariables.userPermissions));

export const checkUserIsAuth = () => {
  const token = getAccessToken();
  return !!token;
};

export const saveLoginUserData = (token, permissions, userData) => {
  const permissionsList = [
    {
      code: BASIC_PERMISSION,
      name: '*',
    },
    ...permissions,
  ];

  const finalPermissions = permissionsList.map(value => value.code);

  // Encrypty and save in local storage
  localStorage.setItem(keyVariables.userToken, encrypty(token));
  // console.log('token ', token);
  localStorage.setItem(keyVariables.userPermissions, encrypty(JSON.stringify(finalPermissions)));
  localStorage.setItem(keyVariables.userId, encrypty(userData.id.toString()));
  localStorage.setItem(keyVariables.userData, encrypty(JSON.stringify(userData)));
};

export const setUserData = userData => {
  localStorage.setItem(keyVariables.userId, encrypty(userData.id.toString()));
  localStorage.setItem(keyVariables.userData, encrypty(JSON.stringify(userData)));
};

export const getUserData = () => {
  const userData = JSON.parse(getStorageValue(keyVariables.userData));
  if (userData) {
    return userData;
  }
  removeUserFromStorage();
  return {
    firstName: '',
  };
};

export const setLastPageBeforeLogin = value => {
  try {
    return localStorage.setItem(keyVariables.lastPageBeforeLogin, encrypty(value));
  } catch (error) {
    console.log(error);
  }
};

export const getLastPageBeforeLogin = () => {
  try {
    return decrypty(localStorage.getItem(keyVariables.lastPageBeforeLogin));
  } catch (error) {
    return '';
  }
};

export const checkUserTokenIsValid = () => {
  const token = getAccessToken();
  const decodedToken = jwtDecode(token);
  const currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    removeUserFromStorage();
    return false;
  }
  return true;
};

export const savePortalLoginUserData = token => {
  const userToken = 'PORTAL360:user_token';
  localStorage.setItem(userToken, encrypty(token));
};
