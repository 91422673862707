import React from 'react';
import 'antd/dist/antd.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale-provider/pt_BR';
import './pages/global/global.scss';

import Routes from './routes/Routes';
import store from './redux/store';
import PropertiesProvider from './hooks/Properties';
// *** Não remover a importação, esta imagem é usada nos emails e precisa ser processada pelo webpack
import logoEmail from './assets/images/logo_360_oficial_email.png';

const App = () => (
  <Provider store={store}>
    <PropertiesProvider>
      <ConfigProvider locale={ptBR}>
        <Routes />
      </ConfigProvider>
    </PropertiesProvider>
  </Provider>
);

App.propTypes = {};

App.defaultProps = {};

export default App;
