import { get, put, post, portalPost, portalPut } from './data_repository';
import { decrypty, handleCleanBookingStorage } from '../utils/helpers';
import { LOCAL_STORAGE } from '../assets/constants/constants';

export const userAuthenticate = ({ email, password, bookingId }) => {
  const loginEndpoint = '/auth/signin/';
  const params = {
    email,
    password,
  };
  if (bookingId) {
    params.bookingId = bookingId;
  }
  return new Promise((resolve, reject) => {
    post({
      url: loginEndpoint,
      params,
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const userAuthenticateWithFacebook = ({ accessToken, bookingId }) => {
  const loginEndpoint = '/auth/signinWithFacebook/';
  const params = {
    accessToken,
  };
  if (bookingId) {
    params.bookingId = bookingId;
  }
  return new Promise((resolve, reject) => {
    post({
      url: loginEndpoint,
      params,
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const userAuthenticateWithGoogle = ({ accessToken, bookingId }) => {
  const loginEndpoint = '/auth/signinWithGoogle/';
  const params = {
    accessToken,
  };
  if (bookingId) {
    params.bookingId = bookingId;
  }
  return new Promise((resolve, reject) => {
    post({
      url: loginEndpoint,
      params,
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const userLogout = () => {
  let logoutEndpoint = '/auth/logout/';
  const bookingId = decrypty(localStorage.getItem(LOCAL_STORAGE.bookingId));
  if (bookingId) {
    logoutEndpoint = `${logoutEndpoint}?bookingId=${bookingId}`;
  }
  handleCleanBookingStorage();

  return new Promise((resolve, reject) => {
    post({
      url: logoutEndpoint,
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const userSignUp = ({ firstName, lastName, email, telephone, password, country }) => {
  const signUpEndPoint = '/auth/signup/';

  return new Promise((resolve, reject) => {
    post({
      url: signUpEndPoint,
      params: {
        firstName,
        lastName,
        email,
        telephone,
        password,
        country,
      },
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const resendEmailConfirmation = ({ email }) => {
  const resendEmailEndPoint = `/auth/resendEmailConfirmation/${email}`;

  return new Promise((resolve, reject) => {
    get({
      url: resendEmailEndPoint,
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const emailConfirmation = ({ token }) => {
  const emailConfirmationEndPoint = `/auth/emailConfirmation/${token}`;

  return new Promise((resolve, reject) => {
    put({
      url: emailConfirmationEndPoint,
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const forgotPassword = ({ email }) => {
  const forgotPasswordEndPoint = `/auth/forgotPassword/${email}`;

  return new Promise((resolve, reject) => {
    post({
      url: forgotPasswordEndPoint,
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const resetPassword = ({ token, newPassword }) => {
  const resetPasswordEndPoint = '/auth/passwordReset/';

  return new Promise((resolve, reject) => {
    put({
      url: resetPasswordEndPoint,
      params: {
        token,
        newPassword,
      },
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const portalAuthenticate = ({ username, password }) => {
  const loginEndpoint = '/signin';

  return new Promise((resolve, reject) => {
    portalPost({
      url: loginEndpoint,
      params: {
        username,
        password,
      },
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

export const portalForgotPassword = ({ email }) => {
  const resetPasswordEndpoint = '/users/reset-password'; 

  return new Promise((resolve, reject) => {
    portalPut({
      url: resetPasswordEndpoint,
      params: {
        email,
      },
    })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
};

