"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const constants_1 = require("../assets/constants/constants");
const hubspotFormKeys = {
    checkin: '7b918a5a-cb55-4b88-8b6c-811ea3dcf840',
    cadastro: '1142d632-5caf-4768-9b94-721ef5e43078',
    pagamento: '7b918a5a-cb55-4b88-8b6c-811ea3dcf840',
    updatecheckin: '9c8f4ed3-b1a6-4ee6-a082-9426b6dd2877',
    facebookform: '087e3bd7-37b2-4690-a392-f8a0a333cd53',
    googleform: 'ebf2a113-c94d-4abb-bb74-4db434346db7',
    login: 'faeb5281-c5c5-46da-8e70-27288bc42761',
    loginfb: '65075ac3-53ca-48e6-a403-7bedefe7c48d',
    logingoogle: 'e2ed8ff2-2a54-4656-bd8c-86494379765f',
    default: '',
};
const useHubspot = () => {
    const [body, setBody] = (0, react_1.useState)({});
    const [action, setAction] = (0, react_1.useState)('');
    const dispatch = (args, action) => {
        action && setAction(action);
        setBody(args);
    };
    function getCookie(name) {
        var _a;
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`) || [];
        if (parts.length === 2) {
            return (_a = parts.pop()) === null || _a === void 0 ? void 0 : _a.split(';').shift();
        }
        return undefined;
    }
    const submitData = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const portalId = constants_1.HUBSPOT_PORTAL;
        let pageName = ((_a = body === null || body === void 0 ? void 0 : body.context) === null || _a === void 0 ? void 0 : _a.pageName) || 'default';
        const formGuid = hubspotFormKeys[pageName.toLocaleLowerCase()];
        const cookieToken = getCookie('hubspotutk');
        if (pageName === 'updateCheckin')
            pageName = 'Atualização do Checkin';
        if (pageName.toLocaleLowerCase().match(/form/))
            pageName = 'Cadastro';
        if (pageName.toLocaleLowerCase().match(/login/))
            pageName = 'Login';
        if (cookieToken) {
            const params = {
                fields: body.fields,
                context: Object.assign(Object.assign({}, body.context), { pageName, hutk: cookieToken }),
                submittedAt: new Date().getTime().toString(),
            };
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            if (Object.values(params.context).every(el => el) && ((_b = params === null || params === void 0 ? void 0 : params.fields) === null || _b === void 0 ? void 0 : _b.length))
                yield fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(params),
                }).catch(error => console.error(error));
        }
    }), [body]);
    (0, react_1.useEffect)(() => {
        submitData();
    }, [body, submitData]);
    return { dispatch };
};
exports.default = useHubspot;
