import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { GeneralMessages } from '../assets/strings/strings';
import MessageUtils from '../utils/message_utils';
import { checkUserIsAuth, getUserPermissions, removeUserFromStorage } from '../utils/users';
import { routesPaths } from './RoutesPaths';

const AuthRoute = ({ component: Component, ...rest }) => {
  const userPermissions = getUserPermissions() || [];
  console.log(userPermissions);
  return (
    <Route
      {...rest}
      render={props => {
        if (!checkUserIsAuth()) {
          // Clean storage token
          removeUserFromStorage();
          return <Redirect to={{ pathname: routesPaths.login }} />;
        }

        // if user has no permission
        if (!(rest.permissions || []).some(element => userPermissions.includes(element))) {
          MessageUtils.warning({
            message: GeneralMessages.permissionDenied,
            description: GeneralMessages.redirectToHome,
          });

          if (props.location.pathname === routesPaths.root) {
            removeUserFromStorage();
            return <Redirect to={{ pathname: routesPaths.login }} />;
          }
          return <Redirect to={{ pathname: routesPaths.root }} />;
        }

        if (checkUserIsAuth()) {
          return (
            // <Layout>
            <Component {...props} />
            // </Layout>
          );
        }
        return <Redirect to={{ pathname: routesPaths.login }} />;
      }}
    />
  );
};

AuthRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      contractId: PropTypes.string,
    }),
  }),
  component: PropTypes.func.isRequired,
};

AuthRoute.defaultProps = {
  location: {
    pathname: '',
  },
  match: {
    params: {},
  },
};

export default AuthRoute;
