import { notification } from 'antd';
const NotificationSucessElementForm = () => {
  notification.success({
    message: 'OK',
    description: 'Formulário preenchido! Entraremos em contato o mais breve possível',
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};

const NotificationSucessElementInformations = () => {
  notification.success({
    message: 'OK',
    description: 'Solicitação enviada! Entraremos em contato o mais breve possível',
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};

const NotificationWarningElement = () => {
  notification.warning({
    message:  'Warning',
    description: 'Favor verificar se os campos estão preenchidos corretamente!',
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};

const NotificationErrorElement = () => {
  notification.error({
    message: 'Error',
    description: 'Aconteceu algum erro inesperado!',
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};

export {NotificationSucessElementForm, NotificationSucessElementInformations ,NotificationWarningElement, NotificationErrorElement}
