// React Imports
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter, Prompt } from 'react-router-dom';

// External Packages

// Redux
import { connect } from 'react-redux';

// Ant Design, Icons and Stylesheet
import {
  Form, Button, Input, Row, Col, Modal,
} from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import './ChangePassword.scss';
import { changeHeaderTitle } from '../../redux/actions/app';

// Assets, Constants and Resources
import { ChangePasswordMessages } from '../../assets/strings/strings';
import { ChangePasswordCodes } from '../../assets/constants/response_codes';
import colors from '../../assets/styles/colors';

// Services, Requests and Utilities
import { routesPaths } from '../../routes/RoutesPaths';
import MessageUtils from '../../utils/message_utils';
import { changePassword } from '../../dataSource/user_requests';
import { fieldPasswordRules } from '../../utils/validations';
import { useAsyncState } from '../../utils/asyncState';

// Components
import NewPassword from '../../components/newPassword/NewPassword';

function ChangePassword(props) {
  const history = useHistory();

  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState(false);
  const [isLeavingByCancelButton, setLeavingByCancelButton] = useAsyncState(false);

  const { changeHeaderTitle } = props;

  useEffect(() => {
    changeHeaderTitle(ChangePasswordMessages.pageTitle);

    return function cleanup() {
      changeHeaderTitle('');
    };
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const response = await changePassword({
      oldPassword: form.getFieldValue('oldPassword'),
      newPassword: form.getFieldValue('password'),
    });

    setLoading(false);

    if (response.code === ChangePasswordCodes.SUCCESS) {
      MessageUtils.success({ message: ChangePasswordMessages.passwordUpdatedSuccessfully });
      history.push(routesPaths.root);
    } else {
      switch (response.code) {
        case ChangePasswordCodes.error.INVALID_OLD_PASSWORD:
          MessageUtils.error({
            message: ChangePasswordMessages.incorrectPassword,
            description: ChangePasswordMessages.incorrectPasswordTryAgain,
          });
          break;

        default:
          MessageUtils.error();
          break;
      }
    }
  };

  const showPromptOnLeave = () => {
    const params = {
      oldPassword: form.getFieldValue('oldPassword'),
      password: form.getFieldValue('password'),
      confirmPassword: form.getFieldValue('confirmPassword'),
    };

    if ((params.oldPassword === undefined || params.oldPassword.length === 0)
      && (params.password === undefined || params.password.length === 0)
      && (params.confirmPassword === undefined || params.confirmPassword.length === 0)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleBack = () => {
    if (showPromptOnLeave()) {
      Modal.confirm({
        title: ChangePasswordMessages.updatedDataWillBeLost,
        content: ChangePasswordMessages.areYouSureWantCancel,
        okText: ChangePasswordMessages.yesCancel,
        cancelText: ChangePasswordMessages.no,
        autoFocusButton: null,
        icon: <WarningTwoTone twoToneColor={colors.warning} />,
        onOk: async () => {
          await setLeavingByCancelButton(true);
          history.push(routesPaths.root);
        },
      });
    } else {
      history.push(routesPaths.root);
    }
  };

  return (
    <div className="ChangePassword">
      <Prompt
        message={
          (location) => {
            if (location.pathname !== routesPaths.changePassword) {
              if (showPromptOnLeave() && !isLeavingByCancelButton) {
                return `${ChangePasswordMessages.areYouSureWantCancel}\n${ChangePasswordMessages.updatedDataWillBeLost}`;
              }
            }
          }
        }
      />

      <Form
        form={form}
        className="ChangePasswordForm"
        onFinish={() => handleSubmit()}
        layout="vertical"
      >
        <div className="page-content">
          <Form.Item
            label={ChangePasswordMessages.currentPassword}
            name="oldPassword"
            rules={fieldPasswordRules()}
          >
            <Input.Password
              className="InputField"
              placeholder={ChangePasswordMessages.currentPassword}
            />
          </Form.Item>

          <Form.Item>
            <NewPassword
              form={form}
              showLabel
              passwordPlaceHolder={ChangePasswordMessages.newPasswordPlaceholder}
              confirmPasswordPlaceHolder={ChangePasswordMessages.confirmNewPasswordPlacholder}
            />
          </Form.Item>
        </div>

        <Row gutter={24} className="actions-container">
          <Col
            lg={{ offset: 10, span: 14 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Button
              className="SecondaryButton cancel"
              loading={isLoading}
              onClick={() => handleBack()}
            >
              {ChangePasswordMessages.cancel.toUpperCase()}
            </Button>

            <Button
              className="PrimaryButton save"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {ChangePasswordMessages.changePasswordSubmit.toUpperCase()}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  changeHeaderTitle,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
