import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './DraggableScroll.scss';

const CardWidth = 384.5;
const DraggableScroll = ({ children, withArrows = false, itemsLength = 0 }) => {
  const ref = useRef(null);

  const [maxScroll, setMaxScroll] = useState(0);

  const [{ isScrolling, clientX, scrollX }, setScrollHandler] = useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
  });

  useEffect(() => {
    setMaxScroll((itemsLength - 1) * CardWidth);
  }, [itemsLength]);

  const onMouseDown = e => {
    e.persist();
    setScrollHandler(oldState => ({
      ...oldState,
      isScrolling: true,
      clientX: e.clientX,
    }));
  };

  const onMouseUp = e => {
    e.persist();
    setScrollHandler(oldState => ({
      ...oldState,
      isScrolling: false,
    }));
  };

  const onMouseMove = e => {
    e.persist();
    if (isScrolling) {
      setScrollHandler(oldState => ({
        ...oldState,
        scrollX: scrollX - e.clientX + clientX,
        clientX: e.clientX,
      }));
    }
  };

  useEffect(() => {
    if (isScrolling) {
      if (ref.current) {
        ref.current.scrollLeft = scrollX;
      }
    }
  }, [scrollX, isScrolling]);

  const leftClick = useCallback(() => {
    const scrollLeft = value => {
      ref.current.scrollLeft = value;
      setScrollHandler(oldState => ({ ...oldState, scrollX: value }));
    };
    if (scrollX >= CardWidth) {
      scrollLeft(scrollX - CardWidth);
    } else {
      scrollLeft(0);
    }
  }, [scrollX]);

  const rightClick = useCallback(() => {
    const scrollRight = value => {
      ref.current.scrollLeft = value;
      setScrollHandler(oldState => ({ ...oldState, scrollX: value }));
    };
    if (scrollX + CardWidth <= maxScroll) {
      scrollRight(scrollX + CardWidth);
    } else if (scrollX + (maxScroll - scrollX) <= maxScroll) {
      scrollRight(scrollX + (maxScroll - scrollX));
    }
  }, [scrollX, maxScroll]);

  return (
    <>
      {withArrows && <LeftOutlined className="arrows" onClick={leftClick} />}

      <div
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        className="DraggableScroll"
      >
        {children}
      </div>
      {withArrows && <RightOutlined className="arrows" onClick={rightClick} />}
    </>
  );
};

DraggableScroll.defaultProps = {
  children: null,
};

DraggableScroll.propTypes = {
  children: PropTypes.node,
};

export default DraggableScroll;
