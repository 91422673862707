"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconCircle = exports.StyledButton = exports.Container = void 0;
const styled_components_1 = __importStar(require("styled-components"));
exports.Container = styled_components_1.default.div `
  margin-top: 25px;
  .groupWrapper {
    width: 40%;

    .priceGroup {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .commonText {
      font-weight: 500;
      font-size: 1.1rem;
    }

    .couponText {
      font-weight: bold;
      color: #c7305a;
    }

    .totalText {
      font-weight: bold;
      font-size: 2rem;
    }

    .totalValue {
      font-weight: bold;
      font-size: 1.4rem;
      span {
        font-size: 2rem;
      }
    }
  }
  .diffs {
    width: 45%;
    .iconGroup {
      display: flex;
      justify-content: space-between;
    }
  }
  .flex {
    display: flex;
    width: 45%;
    align-items: center;
    justify-content: space-between;
  }
  .buttonsRow {
    display: flex;
  }
`;
exports.StyledButton = styled_components_1.default.button `
  background-color: #f6cf0a;
  color: #000000;
  border: none;
  border-radius: 5px;
  padding: 10px 30px;
  font-weight: 400;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &#reserveDetail {
    background-color: #fff;
    box-shadow: 0 0 0 2px #f6cf0ab0;
    &:hover {
      box-shadow: 0 0 0 2.5px #f6cf0ab0;
    }
    &:active {
      box-shadow: 0 0 0 1px #f6cf0ab0;
    }
  }
  &:hover {
    box-shadow: 0 0 0 2px #f6cf0ab0;
  }
  &:active {
    box-shadow: 0 0 0 0px #f6cf0ab0;
  }
`;
exports.IconCircle = styled_components_1.default.div `
  width: 31px;
  height: 31px;
  background-color: ${(props) => (props.active ? '#c7305a' : '#fff')};
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => !props.active &&
    (0, styled_components_1.css) `
      border: 1px solid #777;
    `};
`;
