import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from 'antd';
import './ResetPassword.scss';
import { ResetPasswordCodes } from '../../assets/constants/response_codes';
import { ResetPasswordMessages } from '../../assets/strings/strings';
import { resetPassword } from '../../dataSource/auth_requests';
import NewPassword from '../../components/newPassword/NewPassword';
import MessageUtils from '../../utils/message_utils';
import { routesPaths } from '../../routes/RoutesPaths';
import AccessWrapper from '../accessPages/accessWrapper/AccessWrapper';

const ResetPassword = () => {
  const history = useHistory();

  const { token } = useRouteMatch().params;

  const [isLoading, setLoading] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);

  const [form] = Form.useForm();

  async function handleSubmit() {
    const password = form.getFieldValue('password');

    setLoading(true);

    const response = await resetPassword({
      token,
      newPassword: password,
    });

    setLoading(false);

    switch (response.code) {
      case ResetPasswordCodes.SUCCESS:
        MessageUtils.success({
          message: ResetPasswordMessages.passwordResetSuccessfully,
          description: ResetPasswordMessages.youCanLogiNow,
        });
        setEmailChanged(true);
        break;

      case ResetPasswordCodes.error.INVALID_TOKEN:
        MessageUtils.error({
          description: ResetPasswordMessages.notFoundResetPasswordToken,
        });
        break;

      case ResetPasswordCodes.error.TOKEN_NOT_FOUND:
        MessageUtils.error({
          description: ResetPasswordMessages.invalidTokenResetPassword,
        });
        break;

      default:
        MessageUtils.error();
        break;
    }
  }

  useEffect(() => {
    document.title = '360 Suítes - Resetar senha';
  }, []);

  return (
    <div className="ResetPassword">
      <AccessWrapper showTerms={false}>
        <div className="form-container">
          <div className="reset-header">
            <span>Redefinir</span> senha
          </div>
          {!emailChanged ? (
            <Form
              form={form}
              className="reset-form"
              onFinish={() => handleSubmit()}
              layout="vertical"
              noValidate
            >
              <div className="reset-message">
                Informe uma <span>nova senha</span>
              </div>

              <NewPassword form={form} showLabel />

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-reset"
                  loading={isLoading}
                >
                  {ResetPasswordMessages.confirm}
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div className="success-message">
              <span>{ResetPasswordMessages.passwordResetSuccessfully}</span>
              <br />
              {ResetPasswordMessages.youCanLogiNow}
            </div>
          )}
          <div className="reset-footer">
            <div className="row">
              <span>&nbsp;</span>
              <a onClick={() => history.push(routesPaths.root)}>
                {ResetPasswordMessages.backToLogin}
              </a>
            </div>
          </div>
        </div>
      </AccessWrapper>
    </div>
  );
  //
  // return (
  //   <div className="ResetPassword">
  //     <BaseFormComponent>
  //       <div>
  //         <Form
  //           form={form}
  //           onFinish={() => handleFinish()}
  //           layout="vertical"
  //           className="form"
  //           noValidate
  //         >
  //           <NewPassword form={form} showLabel />
  //
  //           <Form.Item>
  //             <Button
  //               type="primary"
  //               htmlType="submit"
  //               className="PrimaryButton"
  //               loading={isLoading}
  //             >
  //               {ResetPasswordMessages.confirmButton}
  //             </Button>
  //           </Form.Item>
  //
  //           <HyperlinkButton onClick={() => history.push(routesPaths.root)}>
  //             {ResetPasswordMessages.backToLogin}
  //           </HyperlinkButton>
  //         </Form>
  //       </div>
  //     </BaseFormComponent>
  //   </div>
  // );
};

export default ResetPassword;
