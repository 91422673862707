import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { FacebookPixelLocation } from '../components/facebookPixelLocation/FacebookPixelLocation';
import { BASIC_PERMISSION, ProfilePermissions } from '../assets/constants/permissions_feature';
import '../assets/styles/styles.scss';
import ForgotPassword from '../pages/accessPages/forgotPassword/ForgotPassword';
import Login from '../pages/accessPages/login/Login';
import Register from '../pages/accessPages/register/Register';
/* Site Pages */
import AccountInfo from '../pages/accountInfo/AccountInfo';
import BookSuite from '../pages/bookSuite/BookSuite';
import ChangePassword from '../pages/changePassword/ChangePassword';
import EmailConfirmationComponent from '../pages/emailConfirmation/EmailConfirmation';
import Home from '../pages/home/Home';
import LandingPage from '../pages/landingPage/LandingPage';
import NotFound from '../pages/notFound/NotFound';
import PaymentBooking from '../pages/paymentBooking/PaymentBooking';
import CheckIn from '../pages/paymentBooking/CheckIn';
import Politics from '../pages/politics/Politics';
import Privacy from '../pages/politics/Privacy';
import ResetPassword from '../pages/resetPassword/ResetPassword';
import AuthRoute from './AuthRoute';
import { routesPaths } from './RoutesPaths';
import ConfirmedBooking from '../pages/paymentBooking/ConfirmedBooking';

const hsq = (window._hsq = window._hsq || []);

function sendPageView(pathname) {
  hsq.push(['setPath', pathname]);
  hsq.push(['trackPageView']);
}

function ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

function DeleteStorageSafari(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname !== routesPaths.privacy && pathname !== routesPaths.terms && pathname !== routesPaths.cancel) {
      localStorage.removeItem('XP360:isSafariReload');
    }
  }, [pathname]);

  return props.children;
}

function HubspotTracker(props) {
  const { pathname, search } = useLocation();
  useEffect(() => {
    sendPageView(pathname);
  }, [pathname, search]);

  return props.children;
}

const RouteScrollToTop = withRouter(ScrollToTop);
const RouteDeleteStorageSafari = withRouter(DeleteStorageSafari);
const RouteHubspotTracker = withRouter(HubspotTracker);
const RouteFacebookPixelLocation = withRouter(FacebookPixelLocation);

const PoliticPrivacy = <Politics content={Privacy} title="Políticas de Privacidade" />;

export default function Routes() {
  return (
    <Router>
      <RouteScrollToTop>
        <RouteDeleteStorageSafari>
          <RouteHubspotTracker>
            <RouteFacebookPixelLocation>
              <Switch>
                <Route exact path={routesPaths.root} component={Home} />

                <Route exact path={routesPaths.login} component={Login} />

                <Route exact path={routesPaths.register} component={Register} />

                <Route path={routesPaths.forgotPassword} component={ForgotPassword} />

                <Route path={`${routesPaths.emailConfirmation}/:token`} component={EmailConfirmationComponent} />

                <Route path={`${routesPaths.resetPassword}/:token`} component={ResetPassword} />

                <Route path={routesPaths.bookSuite} component={BookSuite} />

                <Route path={`${routesPaths.paymentBooking}/:abandonedCartToken`} component={PaymentBooking} />

                <Route path={routesPaths.paymentBooking} component={PaymentBooking} />

                <Route path={routesPaths.confirmedBooking} component={ConfirmedBooking} />

                <Route path={routesPaths.checkIn} component={CheckIn} />

                <Route path={routesPaths.landingPage} component={LandingPage} />

                {/* <Route path={routesPaths.whoWeAre} component={WhoWeAre} /> */}

                <Route path={routesPaths.privacy} render={() => <Politics type="privacy" />} />
                <Route path={routesPaths.cancel} render={() => <Politics type="cancel" />} />
                <Route path={routesPaths.terms} render={() => <Politics type="terms" />} />

                <AuthRoute exact path={routesPaths.initial} component={Home} permissions={[BASIC_PERMISSION]} />

                <AuthRoute
                  path={`${routesPaths.myAccount}/:subPath?`}
                  component={AccountInfo}
                  permissions={[ProfilePermissions.EDIT_MY_PROFILE]}
                />

                <AuthRoute
                  path={routesPaths.changePassword}
                  component={ChangePassword}
                  permissions={[ProfilePermissions.CHANGE_PASSWORD]}
                />

                <Route component={NotFound} />
              </Switch>
            </RouteFacebookPixelLocation>
          </RouteHubspotTracker>
        </RouteDeleteStorageSafari>
      </RouteScrollToTop>
    </Router>
  );
}
