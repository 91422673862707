import { del, get, put } from './data_repository';

function getMyProfile() {
  const endPoint = '/user/myProfile/get/';

  return new Promise((resolve, reject) => {
    get({ url: endPoint })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
}

function editMyProfile(newProfile) {
  const endPoint = '/user/myProfile/edit/';

  return new Promise((resolve, reject) => {
    put({ url: endPoint, params: newProfile })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
}

function editMyPolitics(politics) {
  const endPoint = '/user/myProfile/edit/politics';

  return new Promise((resolve, reject) => {
    put({ url: endPoint, params: politics })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
}

function changePassword({ oldPassword, newPassword }) {
  const endPoint = '/user/updatePassword/';

  return new Promise((resolve, reject) => {
    put({ url: endPoint, params: { oldPassword, newPassword } })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
}

function editMyEmail({ email }) {
  const endPoint = '/user/myProfile/edit/email/';

  return new Promise((resolve, reject) => {
    put({ url: endPoint, params: { email } })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
}

function deleteMyProfile() {
  const endpoint = '/user/-1';

  return new Promise((resolve, reject) => {
    del({ url: endpoint })
      .then(result => resolve(result))
      .catch(error => reject(error));
  });
}

export { getMyProfile, editMyProfile, changePassword, editMyEmail, deleteMyProfile, editMyPolitics };
