import React from 'react';
import './underline.scss';

const Underline = props => (
  <div {...props} className="divisor">
    &nbsp;
    </div>
);

export default Underline;
