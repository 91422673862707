"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateContainer = exports.DateInput = exports.DatePickerStyled = exports.Label = exports.TitleMenu = exports.CheckBoxGroup = exports.MenuDiv = exports.CollapseStyled = exports.Container = void 0;
const antd_1 = require("antd");
const styled_components_1 = __importStar(require("styled-components"));
const { Group } = antd_1.Checkbox;
const rotate = (0, styled_components_1.keyframes) `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);

  }
`;
exports.Container = styled_components_1.default.div `
  height: auto;
  @media (max-width: 992px) {
    font-size: 1em;
    margin-bottom: 25px;
    max-height: auto;
    transition: max-height 0.6s;
  }
  ${({ isClosed }) => isClosed &&
    (0, styled_components_1.css) `
      display: flex;
      flex-direction: column;
      opacity: 1;
      @media (min-width: 993px) {
        * {
          max-width: 0 !important;
          width: 0;
          display: none;
        }
        img {
          max-width: 0;
        }
        max-width: 0;
      }
      @media (max-width: 992px) {
        display: flex;
        height: 580px;
        max-height: 0;
        transition: all 0.3s;
        margin-bottom: 0;
        .ant-form,
        .guest-component {
          opacity: 0;
        }
      }
    `}
`;
exports.CollapseStyled = (0, styled_components_1.default)(antd_1.Collapse) `
  border-bottom: 1px solid #707070;

  & > .ant-collapse-item-active {
    & > div > .ant-collapse-arrow {
      animation: ${rotate} 0.5s forwards;
    }
  }
  & > div > div {
    padding-left: 0px !important;
    color: #bfc1ca !important;

    & > .ant-collapse-arrow {
      top: 11px !important;
      width: auto !important;
      font-size: 1.5em !important;
      color: black !important;
    }
  }
  background-color: rgba(0, 0, 0, 0);
`;
exports.MenuDiv = styled_components_1.default.div `
  display: flex;
  width: 100%;
  border-bottom: 1px solid #707070;
  justify-content: space-between;
  display: flex;
  min-height: 25px;
  padding-right: 10px;
  flex-wrap: wrap;

  .SelectGuestComponent {
    height: 32px;
    width: 1;
    flex: 1;
    .select-content {
      display: flex;
      padding: 0 !important;
      width: 100%;
    }
  }
  svg {
    width: auto;
    font-size: 1.5em;
    color: black !important;
  }
`;
exports.CheckBoxGroup = (0, styled_components_1.default)(Group) `
  display: flex;
  transition: height 0.6s ease-in;
  flex-direction: column;
  width: 100%;
  & > label {
    font-size: 1.2em;
    margin-bottom: 25px;

    & > span > span {
      width: 20px;
      height: 20px;
    }
  }
`;
exports.TitleMenu = styled_components_1.default.div `
  width: 100%;
  font-size: 2em;
  &:after {
    color: #c31c4a;
    content: ' ${props => props.locale}';
  }
`;
exports.Label = styled_components_1.default.span `
  display: flex;
  padding: 25px 0;
  font-size: 1.5em;
  font-weight: 500;
  width: 100%;
`;
exports.DatePickerStyled = (0, styled_components_1.default)(antd_1.DatePicker.RangePicker) `
  width: 100%;
  opacity: 0;
`;
exports.DateInput = styled_components_1.default.input.attrs({
    type: 'text',
}) `
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
exports.DateContainer = styled_components_1.default.div `
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 15px;
  width: 100%;
`;
