"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
require("./Telephone.scss");
const ddiList_1 = require("../../assets/constants/ddiList");
const strings_1 = require("../../assets/strings/strings");
const TelephoneStyle_1 = require("./TelephoneStyle");
const suiteFormStyle_1 = require("../suiteCollapse/suiteForm/suiteFormStyle");
// Insere em um objeto, por um caminho vindo em um array
function insertByArrayPath(list, obj, value) {
    let objResponse;
    if (typeof list[0] === 'number') {
        objResponse = [...obj];
    }
    else {
        objResponse = Object.assign({}, obj);
    }
    // Caso seja o ultimo elemento, insere o valor e inicia o retorno da recursividade
    if (list.length === 1) {
        objResponse[list[0]] = value;
        return objResponse;
    }
    // Caso a chave não exista no objeto, inicia a chave, de acordo com o tipo do próximo elemento
    if (!objResponse[list[0]]) {
        if (typeof list[1] === 'number') {
            objResponse[list[0]] = [];
        }
        else {
            objResponse[list[0]] = {};
        }
    }
    // Insere o valor na chave, de acordo com o tipo
    if (typeof list[0] === 'number') {
        objResponse[list[0]] = insertByArrayPath(list.slice(1, list.length), objResponse[list[0]], value);
        return objResponse;
    }
    return Object.assign(Object.assign({}, objResponse), { [list[0]]: insertByArrayPath(list.slice(1, list.length), objResponse[list[0]], value) });
}
const Telephone = ({ form, type = 'all', required = false, ddiFieldName, phoneFieldName, ddiFormItemProps, phoneFormItemProps, ddiSelectProps = { size: 'middle' }, phoneInputProps = { placeholder: '' }, onChange = () => { }, }) => {
    const [isDdi55, setIsDdi55] = (0, react_1.useState)(true);
    const [maskType, setMaskType] = (0, react_1.useState)('(11) 11111-1111');
    const phoneHandleMask = (0, react_1.useCallback)(() => {
        const ddi = form.getFieldValue(ddiFieldName);
        const phone = form.getFieldValue(phoneFieldName) || '';
        if (typeof phoneFieldName === 'string') {
            if (ddi === '55') {
                setIsDdi55(true);
                form.setFieldsValue({ [`${phoneFieldName}`]: phone.replace(/\D/g, '').slice(0, 11) });
            }
            else {
                setIsDdi55(false);
                form.setFieldsValue({ [`${phoneFieldName}`]: phone.replace(/\D/g, '') }); // Only numbers
            }
        }
        else {
            // Tansforma em array
            const nameArray = Array.prototype.slice.call(phoneFieldName);
            if (ddi === '55') {
                setIsDdi55(true);
                const fieldsValue = insertByArrayPath(nameArray, form.getFieldsValue(), phone.replace(/\D/g, '').slice(0, 11));
                form.setFieldsValue(fieldsValue);
            }
            else {
                setIsDdi55(false);
                const fieldsValue = insertByArrayPath(nameArray, form.getFieldsValue(), phone.replace(/\D/g, ''));
                form.setFieldsValue(fieldsValue);
            }
        }
        onChange();
    }, [ddiFieldName, phoneFieldName, form]);
    (0, react_1.useEffect)(() => {
        const ddi = form.getFieldValue(ddiFieldName);
        if (ddi === '55') {
            setIsDdi55(true);
        }
        else {
            setIsDdi55(false);
        }
        // refaz a validação
        // form.validateFields([phoneFieldName]);
    }, [form.getFieldValue(phoneFieldName)]);
    (0, react_1.useEffect)(() => {
        if (type === 'telephone') {
            setMaskType('(11) 1111-1111');
        }
        else {
            setMaskType('(11) 11111-1111');
        }
    }, [type]);
    return (react_1.default.createElement("div", { className: "Telephone" },
        react_1.default.createElement(suiteFormStyle_1.FormItemFormulary, Object.assign({}, ddiFormItemProps, { style: {
                marginRight: '5px',
                display: 'block',
            } }),
            react_1.default.createElement(TelephoneStyle_1.SelectStyled, Object.assign({ onChange: phoneHandleMask }, ddiSelectProps), ddiList_1.ddiList.map(v => (react_1.default.createElement(antd_1.Select.Option, { key: v, value: v, onChange: onChange },
                "+",
                v))))),
        react_1.default.createElement(suiteFormStyle_1.FormItemFormulary, Object.assign({ style: { flex: 1 } }, phoneFormItemProps, { rules: [
                {
                    required,
                    message: strings_1.RegisterMessages.validationTelephone,
                },
                () => ({
                    validator(rule, value) {
                        if (!value) {
                            return Promise.resolve();
                        }
                        // É do formato brasileiro
                        if (isDdi55) {
                            if (type === 'cellphone' && value.replace(/\D/g, '').length !== 11) {
                                return Promise.reject(new Error('Insira um número válido'));
                            }
                            if (type === 'telephone' && value.replace(/\D/g, '').length !== 10) {
                                return Promise.reject(new Error('Insira um número válido'));
                            }
                            if (type === 'all' && (value.replace(/\D/g, '').length < 10 || value.replace(/\D/g, '').length > 11)) {
                                return Promise.reject(new Error('Insira um número válido'));
                            }
                        }
                        else {
                            if (value.replace(/\D/g, '').length < 4) {
                                return Promise.reject(new Error('Insira um número válido'));
                            }
                        }
                        return Promise.resolve();
                    },
                }),
            ] }), isDdi55 ? (react_1.default.createElement(TelephoneStyle_1.MaskInputStyled, Object.assign({ type: "tel", onChange: onChange, mask: maskType }, phoneInputProps))) : (react_1.default.createElement(TelephoneStyle_1.MaskInputStyled, Object.assign({ type: "tel", onChange: onChange, mask: maskType, maxLength: 13 }, phoneInputProps))))));
};
exports.default = Telephone;
