"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const suiteFormStyle_1 = require("../suiteFormStyle");
const FormSuite_1 = require("../../../../hooks/FormSuite");
require("./BookingReasonStyles.scss");
const BookingReason = ({ formKey = 'suites' }) => {
    const { form } = (0, FormSuite_1.useFormSuit)();
    const [withError, setWithError] = (0, react_1.useState)(false);
    const options = [
        { label: 'Namorar', value: 'hostingPrivacy' },
        { label: 'Turistar', value: 'hostingTourism' },
        { label: 'Comprar', value: 'hostingPurchases' },
        { label: 'Trabalhar', value: 'hostingWork' },
    ];
    const [checked, setChecked] = (0, react_1.useState)('');
    const handleCheck = (event) => {
        event.persist();
        const key = 0;
        const formValues = form.getFieldsValue();
        formValues[formKey][key].bookingReason = true;
        options.forEach(option => {
            if (option.value === event.target.value)
                formValues[formKey][key][event.target.value] = event.target.checked;
            else
                formValues[formKey][key][option.value] = formValues[formKey][key][option.value] || false;
        });
        if (options.every(option => !formValues[formKey][key][option.value]))
            formValues[formKey][key].bookingReason = undefined;
        form.setFieldsValue(formValues);
        setChecked(event.target.checked ? event.target.value : '');
    };
    (0, react_1.useEffect)(() => {
        const formValues = form.getFieldsValue();
        const key = 0;
        if (!checked)
            options.forEach(option => {
                if (formValues[formKey][key][option.value]) {
                    formValues[formKey][key].bookingReason = option.value;
                    setChecked(option.value);
                    form.setFieldsValue(formValues);
                }
            });
    }, [form.getFieldsValue()]);
    return (react_1.default.createElement("div", { className: `BookingReason ${withError ? 'error' : ''}` },
        react_1.default.createElement("span", { className: "booking-reason-label" }, "Estou me hospedando para:"),
        react_1.default.createElement(suiteFormStyle_1.FormItemFormularyRequired, { className: "checkbox-group", style: {
                display: 'flex',
                flexDirection: 'column',
            }, rules: [
                {
                    required: true,
                    validator: (_, value) => {
                        if (value !== checked) {
                            setWithError(true);
                            return Promise.reject(Error('Por favor, preencha o motivo da hospedagem'));
                        }
                        setWithError(false);
                        return Promise.resolve();
                    },
                },
            ], name: formKey === 'suites' ? [0, 'bookingReason'] : [formKey, 0, 'bookingReason'] },
            react_1.default.createElement("div", { className: "checkbox-group" }, options.map(option => (react_1.default.createElement(antd_1.Checkbox, { value: option.value, onClick: handleCheck, disabled: !!(checked && option.value !== checked), checked: checked === option.value },
                react_1.default.createElement("b", null, option.label))))))));
};
exports.default = BookingReason;
