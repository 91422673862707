const colors = {
  primary: {
    main: '#09ACDA',
    primaryW1: '#60C5E1',
    primaryW2: '#C6EEFA',
    primaryS1: '#026986',
    primaryS2: '#013E4F',
  },

  secondary: '#56CA65',
  terciary: '#FFB302',
  quaternary: '#1D43E0',

  error: '#F5222D',
  information: '#7EC8E3',
  success: '#52C41A',
  warning: '#FFE90B',
};

module.exports = colors;
