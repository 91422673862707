"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortalLoginMessages = exports.AccommodationMessages = exports.PaymentMessages = exports.BookingMessages = exports.RangePickerComponentStrings = exports.HeaderSearchFormMessages = exports.ExampleFilterListMessages = exports.ExamplePaginationListMessages = exports.ExampleListMessages = exports.ExampleForm4Messages = exports.ExampleForm3Messages = exports.ExampleForm2Messages = exports.ExampleForm1Messages = exports.FieldsMessages = exports.DeleteProfileMessages = exports.EditMyProfileMessages = exports.ChangePasswordMessages = exports.BarAndHeaderMessages = exports.NewPasswordMessages = exports.ResetPasswordMessages = exports.ForgotPasswordMessages = exports.EmailConfirmationMessages = exports.RegisterMessages = exports.LoginPageMessages = exports.GeneralMessages = void 0;
exports.GeneralMessages = {
    genericErrorMessage: 'Ocorreu um erro',
    loading: 'Carregando',
    success: 'Sucesso',
    permissionDenied: 'Permissão negada',
    redirectToHome: 'Você não tem permissão para acessar essa funcionalidade. Você será redirecionado para a página inicial.',
};
exports.LoginPageMessages = {
    emailPlaceholder: 'E-mail',
    passwordPlaceholder: 'Senha',
    login: {
        loginButton: 'ENTRAR',
        loginPlaceholder: 'Login',
        forgotPasswordButton: 'esqueci a senha',
        forgotPasswordPortal: 'Esqueceu sua senha?',
        emailNotConfirmed: 'E-mail ainda não confirmado',
        emailOrPasswordInvalid: 'E-mail e/ou senha inválidos',
        verifyAndTryAgain: 'Verifique e tente novamente.',
        emailNotverifyAndTryAgained: 'E-mail ainda não confirmado',
        inactiveUser: 'Usuário inativo',
        contactYourBranch: 'Entre em contato com a sua filial.',
        confirmYouEmailToAccess: 'Confirme seu e-mail para acessar o sistema.',
        resendEmail: 'Reenviar e-mail',
        emailSentVerifyYourInbox: 'E-mail enviado. Verifique sua caixa de entrada.',
        facebookAuthenticationError: 'Não foi possível autenticar com o Facebook.',
        facebookCommunicationError: 'Não foi possível conectar com o Facebook.',
        facebookAccountNotFound: 'Este Facebook não pertence a uma conta registrada.',
        googleAuthenticationError: 'Não foi possível autenticar com o Google.',
        googleCommunicationError: 'Não foi possível conectar com o Google.',
        googleAccountNotFound: 'Esta conta Google não pertence a uma conta registrada.',
        createAccount: 'Clique em criar uma conta',
    },
};
exports.RegisterMessages = {
    personalDataForm: 'DADOS PESSOAIS',
    passwordForm: 'SENHA',
    insertYourName: 'Por favor, insira seu nome.',
    insertYourTelephone: 'Por favor, insira seu telefone.',
    invalidPhoneNumber: 'Telefone inválido',
    passwordAndConfirmPasswordMustBeEquals: 'Senha e confirmar senha devem ser iguais',
    namePlaceholder: 'Nome',
    confirmPasswordPlacholder: 'Confirmar Senha',
    acceptTermsConditionsAndUse: 'Por favor, aceite os Termos e Condições de uso',
    back: 'VOLTAR',
    continue: 'CONTINUAR',
    finishRegister: 'FINALIZAR CADASTRO',
    validationRequired: 'O campo é obrigatório',
    validationTelephone: 'Por favor, insira um Celular',
    validationConfirmPassword: 'As senhas não conferem',
    validationCharactersMinimum5: 'Mínimo de 5 caracteres',
    validationCharactersMinimum6: 'Mínimo de 6 caracteres',
    validationCharactersMaximum200: 'Máximo de 200 caracteres',
    validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
    iReadAndAceptThe: 'Eu li e aceito os',
    termsAndConditionsOfUse: 'Termos e Condições de uso',
    termsAndConditionsFileName: 'Termos e Condições',
    documentNotFound: 'Documento não encontrado',
    emailSentTo1of2: 'Um e-mail de confirmação foi enviado para',
    emailSentTo2of2: 'Verifique sua caixa de entrada.',
    successfulRegistration: 'Cadastro realizado com sucesso',
    emailErrorWhileSignUp: 'E-mail já usado na plataforma',
    emailAlreadyInUse: 'Verifique se o e-mail foi digitado corretamente ou clique em entrar.',
    facebookErrorWhileSignUp: 'Facebook já usado na plataforma',
    facebookAlreadyInUse: 'Este Facebook já está registrado. Clique em entrar.',
    facebookAuthenticationError: 'Não foi possível autenticar com o Facebook.',
    googleErrorWhileSignUp: 'Conta Google já usada na plataforma',
    googleAlreadyInUse: 'Esta conta Google já está registrado. Clique em entrar.',
    googleAuthenticationError: 'Não foi possível autenticar com o Google.',
    genericMessageError: 'Ocorreu um erro na requisição.',
    genericError: 'Tente novamente mais tarde.',
    doLogin: 'Fazer Login',
};
exports.EmailConfirmationMessages = {
    emailConfirmedSuccessfully: 'E-mail confirmado com sucesso!',
    loginNow: 'Agora você já pode fazer o login no aplicativo.',
    invalidToken: 'Token inválido!',
    doLogin: 'Fazer Login',
};
exports.ForgotPasswordMessages = {
    forgotPassword: 'Esqueceu a senha?',
    insertYourEmail: 'Insira seu email cadastrado e te enviaremos as instruções para a recuperação da senha.',
    confirm: 'Confirmar',
    backToLogin: 'Voltar para o site 360 Suítes',
    backToLoginPortal: 'Voltar para login do Portal',
    verifyYourEmail: 'Verifique seu email.',
    instructionsSent: 'Enviamos instruções ao endereço de email informado.',
    userNotFound: 'Usuário não encontrado.',
    verifyAndTryAgain: 'Verifique se o e-mail foi digitado corretamente e tente novamente.',
};
exports.ResetPasswordMessages = {
    passwordResetSuccessfully: 'Senha redefinida com sucesso!',
    youCanLogiNow: 'Agora você pode fazer login no sistema utilizando sua nova senha.',
    notFoundResetPasswordToken: 'Token de redefinição de senha não encontrado.',
    invalidTokenResetPassword: 'Token de redefinição de senha inválido.',
    confirm: 'Salvar',
    backToLogin: 'Voltar para o site 360 Suítes',
};
exports.NewPasswordMessages = {
    passwordsMustMatch: 'Nova senha e confirmar nova senha devem ser iguais',
    invalidPassword: 'As senhas devem ter no mínimo 6 digitos',
    insertYourPassword: 'Por favor, insira sua senha',
    passwordPlaceholder: 'Senha',
    confirmPasswordPlacholder: 'Confirmar senha',
};
exports.BarAndHeaderMessages = {
    logout: 'Sair',
    changePassword: 'Alterar Senha',
    myProfile: 'Meu Perfil',
    home: 'Início',
    profile: 'Perfil',
};
exports.ChangePasswordMessages = {
    pageTitle: 'Alterar Senha',
    currentPassword: 'Senha atual',
    newPasswordPlaceholder: 'Nova senha',
    confirmNewPasswordPlacholder: 'Confirmar nova senha',
    changePasswordSubmit: 'Alterar Senha',
    cancel: 'Cancelar',
    passwordUpdatedSuccessfully: 'Senha alterada com sucesso!',
    incorrectPassword: 'Senha incorreta',
    incorrectPasswordTryAgain: 'Por favor, digite a senha atual corretamente para poder cadastrar uma nova senha',
    updatedDataWillBeLost: 'Os dados alterados serão perdidos!',
    areYouSureWantCancel: 'Tem certeza que deseja cancelar?',
    yesCancel: 'Sim, cancelar sem salvar',
    no: 'Não',
};
exports.EditMyProfileMessages = {
    pageTitle: 'Meu Perfil',
    errorLoadingUserData: 'Ocorreu um erro ao carregar as informações do seu perfil',
    errorLoadingUserDataDetails: 'Não será possível visualizar ou editar as informações do seu perfil. Por favor, tente novamente mais tarde.',
    cancelButton: 'Cancelar',
    backButton: 'Voltar',
    saveButton: 'Salvar',
    fieldName: 'Nome',
    fieldEmail: 'E-mail',
    fieldTelephone: 'Telefone',
    notConfirmedEmail: 'Email não confirmado',
    confirmedEmail: 'Email confirmado',
    loadingData: 'Carregando dados do perfil',
    updatingData: 'Atualizando dados do perfil',
    dataUpdatedSuccessfully: 'Dados atualizados com sucesso!',
    checkEmailAndLoginAgain: 'Agora é necessário confirmar o e-mail alterado e fazer login novamente. Verifique sua caixa de entrada.',
    errorUpdatingData: 'Não foi possível atualizar os dados do seu perfil.',
    emailAlreadyInUse1Of2: 'O e-mail',
    emailAlreadyInUse2Of2: 'já foi utilizado na plataforma. Por favor, altere o e-mail e tente novamente',
    updatedDataWillBeLost: 'Os dados alterados serão perdidos!',
    areYouSureWantCancel: 'Tem certeza que deseja cancelar?',
    yesCancel: 'Sim, cancelar sem salvar',
    no: 'Não',
};
exports.DeleteProfileMessages = {
    deleteProfileSuccessfully: 'Perfil excluído com sucesso!',
};
exports.FieldsMessages = {
    name: {
        insertYourName: 'Por favor, insira seu nome.',
        minimumCharacters: 'Por favor, insira no mínimo 3 caracteres.',
        maximumCharacters: 'O nome deve conter no máximo 150 caracteres.',
    },
    email: {
        insertYourEmail: 'Por favor, insira seu email.',
        invalidEmailFormat: 'Formato de email inválido.',
        dontUseSpecialCharacters: 'Não utilize caracteres especiais, ou acentuados.',
    },
    telephone: {
        insertYourTelephone: 'Por favor, insira seu telefone.',
        invalidPhoneNumber: 'Telefone inválido',
    },
    password: {
        insertYourPassword: 'Por favor, insira sua senha',
        invalidPassword: 'A senha deve ter no mínimo 6 digitos',
    },
};
exports.ExampleForm1Messages = {
    messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
    messageErrorSendingData: 'Erro ao enviar os dados',
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
    validationPleaseFillInTheField: 'Por favor, preencha o campo',
    validationCharactersMaximum200: 'Máximo de 200 caracteres',
    buttomSave: 'Salvar',
    buttomCancel: 'Cancelar',
    spinLoading: 'Carregando...',
};
exports.ExampleForm2Messages = {
    nameField: 'Nome',
    cpfField: 'CPF',
    emailField: 'E-mail',
    passwordField: 'Senha',
    confirmPasswordField: 'Confirmar senha',
    telephoneField: 'Telefone',
    cnpjField: 'CNPJ',
    messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
    messageErrorSendingData: 'Erro ao enviar os dados',
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
    validationCharactersMinimum200: 'Mínimo de 5 caracteres',
    validationCharactersMaximum200: 'Máximo de 200 caracteres',
    validationCPF: 'Insira um CPF válido',
    validationCNPJ: 'Insira um CNPJ válido',
    validationConfirmPassword: 'As senhas não conferem',
    validationEmail: 'Por favor, insira um e-mail válido',
    validationRequired: 'O campo é obrigatório',
    buttomSave: 'Salvar',
    buttomCancel: 'Cancelar',
    spinLoading: 'Carregando...',
};
exports.ExampleForm3Messages = {
    messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
    messageErrorSendingData: 'Erro ao enviar os dados',
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    validationPleaseSelectADate: 'Por favor, selecione uma data',
    validationPleaseSelectARange: 'Por favor, selecione um período',
    buttomSave: 'Salvar',
    buttomCancel: 'Cancelar',
    spinLoading: 'Carregando...',
};
exports.ExampleForm4Messages = {
    cepField: 'CEP',
    stateField: 'Estado',
    cityField: 'Cidade',
    addressField: 'Endereço',
    numberField: 'Número',
    neighborhoodField: 'Bairro',
    complementField: 'Complemento',
    messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
    messageErrorSendingData: 'Erro ao enviar os dados',
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
    validationPleaseFillInTheField: 'Por favor, preencha o campo',
    validationCharactersMaximum200: 'Máximo de 200 caracteres',
    buttomSave: 'Salvar',
    buttomCancel: 'Cancelar',
    spinLoading: 'Carregando...',
};
exports.ExampleListMessages = {
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    spinLoading: 'Carregando...',
};
exports.ExamplePaginationListMessages = {
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    spinLoading: 'Carregando...',
};
exports.ExampleFilterListMessages = {
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    spinLoading: 'Carregando...',
    buttomFilter: 'Filtrar',
    buttomClean: 'Limpar',
    validationCharactersMaximum200: 'Máximo de 200 caracteres',
    validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
};
exports.HeaderSearchFormMessages = {
    messageSuccessfullySubmittedForm: 'Formulário enviado com sucesso',
    messageErrorSendingData: 'Erro ao enviar os dados',
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    validationPleaseSelectAnOption: 'Por favor, selecione uma opção',
    validationPleaseFillInTheField: 'Por favor, preencha o campo',
    validationCharactersMaximum200: 'Máximo de 200 caracteres',
    buttomSearch: 'Pesquisar',
    spinLoading: 'Carregando...',
    placeholderPlace: 'Selecione o local',
    validationPleaseSelectARange: 'Por favor, selecione um período',
};
exports.RangePickerComponentStrings = {
    weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ],
    weekdaysLong: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    firstDayOfWeek: 1,
    labels: { nextMonth: 'Próximo mês', previousMonth: 'Mês anterior' },
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageErrorLoadingData: 'Erro ao carregar os dados',
    spinLoading: 'Carregando...',
    initialDate: 'Entrada',
    finalDate: 'Saída',
    clean: 'Limpar',
};
exports.BookingMessages = {
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageIPUnauthorizedBeds: 'O seu IP não está autorizado na plataforma Beds24',
    messageRoomNotFound: 'O código do quarto selecionado é inválido',
    messageValidationFields: 'Ocorreu um erro na validação dos campos do hóspede, verifique e tente novamente',
    messageNoAvailability: 'Quarto indisponível para o período selecionado',
    messageCannotRetrieveData: 'Erro ao carregar os dados. Por favor, tente novamente mais tarde',
};
exports.PaymentMessages = {
    messageGeneralRequestError: 'Ocorreu um erro na requisição. Por favor, tente novamente mais tarde',
    messageValidationFields: 'Ocorreu um erro na validação dos campos, verifique os campos e tente novamente',
};
exports.AccommodationMessages = {
    accommodationCheckoutSuccessDesktop: 'Check-out da sua hospedagem foi realizado com sucesso! Se possível responda nossa pesquisa de satisfação no formulário que abriu na nova aba.',
    accommodationCheckoutSuccessMobile: 'Check-out da sua hospedagem foi realizado com sucesso! Se possível responda nossa pesquisa de satisfação no formulário que abrirá a seguir.',
};
exports.PortalLoginMessages = {
    message: {
        invalidAccount: 'Usuário ou senha inválidos.',
        userNotFound: 'Este usuário não existe',
    },
    description: {
        invalidAccount: 'Não foi possível acessar, verificar novamente o usuário e a senha.',
        userNotFound: 'Usuário não cadastrado no sistema.',
    },
};
