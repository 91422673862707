"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
require("./SectionTitle.scss");
const Divider = ({ text = '' }) => {
    const [normalText, setNormalText] = (0, react_1.useState)([]);
    const [boldText, setBoldText] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        const tempText = text.split(' ');
        if (tempText.length > 1) {
            setNormalText([tempText.slice(0, tempText.length - 1).join(' ')]);
            setBoldText([` ${tempText[tempText.length - 1]}`]);
        }
        else {
            setBoldText([tempText[tempText.length - 1]]);
        }
    }, [text]);
    return (react_1.default.createElement("div", { className: "Divider" },
        react_1.default.createElement("hr", { className: "line" }),
        react_1.default.createElement("span", { className: "text" },
            normalText,
            react_1.default.createElement("span", null, boldText)),
        react_1.default.createElement("hr", { className: "line" })));
};
exports.default = Divider;
