"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledLink = exports.StyledModal = exports.StyledButton = exports.Text = exports.Title = exports.Column = exports.GridRow = exports.Row = exports.Image = void 0;
const antd_1 = require("antd");
const react_router_dom_1 = require("react-router-dom");
const styled_components_1 = __importStar(require("styled-components"));
exports.Image = styled_components_1.default.img `
  width: 309px;
  height: 221px;
  margin-top: 10px;
  border-radius: 10px;
  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
exports.Row = styled_components_1.default.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.closed &&
    (0, styled_components_1.css) `
      justify-content: flex-end;
      gap: 20px;
    `}
  ${(props) => props.actions &&
    (0, styled_components_1.css) `
      width: 96%auto;
      margin: 2rem 0 0;
      justify-content: flex-end;
    `}
`;
exports.GridRow = styled_components_1.default.div `
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${props => (props === null || props === void 0 ? void 0 : props.childsNumber) && props.childsNumber < 3
    ? (0, styled_components_1.css) `
          grid-template-columns: 67% 33%;
        `
    : (0, styled_components_1.css) `
          grid-template-columns: repeat(3, 1fr);
        `}
`;
exports.Column = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  justify-content: space-between;
  span:first-child {
    font-weight: 500;
  }
  .room-description {
  }
`;
exports.Title = styled_components_1.default.span `
  font-size: 1.6rem;
  font-weight: normal;
  color: #ac1841;
  line-height: 1.38;
`;
exports.Text = styled_components_1.default.span `
  color: ${(props) => props.color || '#000'};
  font-weight: ${(props) => props.decoration || 'normal'};
`;
exports.StyledButton = styled_components_1.default.button `
  background-color: #f6cf0a;
  color: #000000;
  border: none;
  border-radius: 5px;
  padding: 10px 30px;
  font-weight: 400;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 2.5px #f6cf0ab0;
  }
  &:active {
    box-shadow: 0 0 0 0px #f6cf0ab0;
  }
  &#appointAgain {
    background-color: #c7305a;
    color: #fff;
    &:hover {
      box-shadow: 0 0 0 2px #c7305ab0;
    }
    &:active {
      box-shadow: 0 0 0 0px #c7305ab0;
    }
  }
`;
exports.StyledModal = (0, styled_components_1.default)(antd_1.Modal) `
  .ant-modal-content {
    border-radius: 20px;
  }
`;
exports.StyledLink = (0, styled_components_1.default)(react_router_dom_1.Link) `
  &:hover {
    color: #000;
    text-decoration: inherit;
  }
`;
