import { useCallback, useEffect, useState } from 'react';

// TODO: [XP360-623] Refatorar DetectDevice.js para TS
const MOBILE_WIDTH = 992;
export default function useDeviceDetect(maxWidth = MOBILE_WIDTH) {
  const [isMobile, setMobile] = useState(true);
  const [isDesktop, setDesktop] = useState(true);

  const memoWidthWrapper = useCallback(() => {
    setMobile(window.innerWidth <= maxWidth);
    setDesktop(window.innerWidth > maxWidth);
  });

  useEffect(() => {
    setMobile(window.innerWidth <= maxWidth);
    setDesktop(window.innerWidth > maxWidth);
    window.addEventListener('resize', memoWidthWrapper);
    return () => {
      window.removeEventListener('resize', memoWidthWrapper);
    };
  }, [maxWidth, memoWidthWrapper]);

  return { isMobile, isDesktop, width: window.innerWidth };
}
