import React from 'react';
import './SectionFeatured.scss';

export default function SectionFeatured() {
  return <div className="SectionFeatured">Suítes em Destaque</div>;
}

SectionFeatured.propTypes = {};

SectionFeatured.defaultProps = {};
