"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BASIC_PERMISSION = exports.UserPermissions = exports.SystemParametersPermissions = exports.RolePermissions = exports.ProfilePermissions = exports.NotificationsPermissions = void 0;
const NotificationsPermissions = {
    VIEW_NOTIFICATIONS: 'VIEW_NOTIFICATIONS',
    SEND_NOTIFICATIONS: 'SEND_NOTIFICATIONS',
};
exports.NotificationsPermissions = NotificationsPermissions;
const ProfilePermissions = {
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    VIEW_MY_PROFILE: 'VIEW_MY_PROFILE',
    EDIT_MY_PROFILE: 'EDIT_MY_PROFILE',
};
exports.ProfilePermissions = ProfilePermissions;
const RolePermissions = {
    EDIT_ROLES: 'EDIT_ROLES',
};
exports.RolePermissions = RolePermissions;
const SystemParametersPermissions = {
    VIEW_SYSTEM_PARAMETER: 'VIEW_SYSTEM_PARAMETER',
    EDIT_SYSTEM_PARAMETER: 'EDIT_SYSTEM_PARAMETER',
};
exports.SystemParametersPermissions = SystemParametersPermissions;
const UserPermissions = {
    USER_REGISTRATION: 'USER_REGISTRATION',
};
exports.UserPermissions = UserPermissions;
const BASIC_PERMISSION = '';
exports.BASIC_PERMISSION = BASIC_PERMISSION;
