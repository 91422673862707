import * as SuitesTypes from '../actiontypes/suitesReserve';

const INITIAL_STATE = {
  roomActive: '',
  reserve: {
    property: ['94134-J6rvoR0TeJICnNnrr9WEpKb3cH3q4eSL'],
  },
  suites: [
    {
      title: 'Suite 360 Luz Standart',
      listGuests: [
        {
          name: 'Felipe Okino',
          rg: '54.211.470-7',
          birth: '17/07/1997',
          email: 'felipe1okino@live.com',
          phoneNumber: '(16) 99719-8406',
        },
        {
          name: 'Felipe Okino',
          rg: '54.211.470-7',
          birth: '17/07/1997',
          email: 'felipe1okino@live.com',
          phoneNumber: '(16) 99719-8406',
        },
      ],
    },
  ],
  building: {
    name: JSON.stringify([]),
    updatedAt: new Date(),
    showThis: '',
  },
};

const suitesReserve = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SuitesTypes.SET_SPECIFIC_VALUE:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          ...action.payload.value,
        },
      };
    case SuitesTypes.SET_BUILDING_NAME:
      return {
        ...state,
        building: { ...state.building, ...action.payload.value },
      };
    case SuitesTypes.SET_ACTIVE_BUILD:
      return {
        ...state,
        roomActive: action.payload.value,
      };
    default:
      return state;
  }
};

export default suitesReserve;
