import { combineReducers } from 'redux';
import app from './app';
import communication from './communication';
import listRoles from './listRoles';
import listUsers from './listUsers';
import manageRole from './manageRole';
import suitesReserve from './suitesReserve';
import users from './users';

const appReducer = combineReducers({
  app,
  users,
  communication,
  listUsers,
  manageRole,
  listRoles,
  suitesReserve,
});

export default function (state, action = {}) {
  let newState = state;
  if (action.type === 'USER_LOGOUT') {
    newState = undefined;
  }
  return appReducer(newState, action);
}
