import React, { useEffect } from 'react';
import { REACT_APP_BLIP_CHAT_KEY } from '../assets/constants/constants';

const InitializeChatBot = () => {
  useEffect(() => {
    chatBotBlip();
  }, []);

  const chatBotBlip = () => {
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/blip-chat-widget';
    script.async = true;
    document.body.appendChild(script);
    window.onload = function () {
      new BlipChat()
        .withAppKey(REACT_APP_BLIP_CHAT_KEY)
        .withButton({ color: '#bc204b', icon: '' })
        .withCustomCommonUrl('https://chat.blip.ai/')
        .build();
    };
  };

  return <div />;
};

export default InitializeChatBot;
