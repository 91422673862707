"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMyBookings = void 0;
const data_repository_1 = require("./data_repository");
const getMyBookings = () => {
    const endPoint = '/bookings/history';
    return new Promise((resolve, reject) => {
        (0, data_repository_1.get)({ url: endPoint, query: null, params: null, clientOptions: null })
            .then(result => resolve(result))
            .catch(error => reject(error));
    });
};
exports.getMyBookings = getMyBookings;
