"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PagSeguroPromiseResolve = {
    myOnSenderHash: () => {
        return new Promise((resolve, reject) => {
            PagSeguroDirectPayment.onSenderHashReady((response) => {
                if (response.status === 'error') {
                    reject(Error(`On Sender Hash Failed ${response.message}`));
                }
                resolve((console.log(response.senderHash), response.senderHash));
            });
        });
    },
    mySetSessionId: (id) => {
        return new Promise((resolve) => {
            resolve(console.log('Sessão Estabelecida'), PagSeguroDirectPayment.setSessionId(id));
        });
    },
    myGetBrand: (cardBin) => {
        return new Promise((resolve, reject) => {
            PagSeguroDirectPayment.getBrand({
                cardBin,
                success: (response) => {
                    resolve(response);
                },
                error: (response) => {
                    reject(response);
                },
                complete: (response) => {
                    return response;
                },
            });
        });
    },
    myGetInstallments: ({ amount, brand }) => {
        return new Promise((resolve, reject) => {
            PagSeguroDirectPayment.getInstallments({
                amount,
                brand,
                success: (response) => {
                    resolve(response);
                },
                error: (response) => {
                    reject(response);
                },
                complete: (response) => {
                    return response;
                },
            });
        });
    },
    myPaymentMethod: (amount) => {
        return new Promise((resolve, reject) => {
            PagSeguroDirectPayment.getPaymentMethods({
                amount,
                success: (response) => {
                    resolve(response);
                },
                error: (response) => {
                    reject(response);
                },
                complete: (response) => {
                    return response;
                },
            });
        });
    },
    myCreateCardToken: ({ brand, cardNumber, cvv, expirationMonth, expirationYear, }) => {
        return new Promise((resolve, reject) => {
            PagSeguroDirectPayment.createCardToken({
                brand,
                cardNumber,
                cvv,
                expirationMonth,
                expirationYear,
                success: (response) => {
                    resolve(response);
                },
                error: (response) => {
                    reject(response);
                },
                complete: (response) => {
                    return response;
                },
            });
        });
    },
};
exports.default = PagSeguroPromiseResolve;
