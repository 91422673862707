"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordPortalInvestidorCodes = exports.PaymentCodes = exports.PreCheckin = exports.BookingCodes = exports.DeleteProfileCodes = exports.BookingReservationCodes = exports.EditMyProfileCodes = exports.GetMyProfileCodes = exports.ChangePasswordCodes = exports.ResetPasswordCodes = exports.ForgotPasswordCodes = exports.EmailConfirmationCodes = exports.RegisterCodes = exports.PortalLoginCodes = exports.LoginCodes = exports.HTTPCodes = void 0;
exports.HTTPCodes = {
    SUCCESS: 200,
    INVALID_ACCESS_TOKEN_ERROR_CODE: 401,
};
exports.LoginCodes = {
    success: 2000,
    error: {
        INVALID_CREDENTIALS: -2000,
        EMAIL_NOT_CONFIRMED: -2001,
        USER_NOT_ACTIVATED: -2002,
        FACEBOOK_AUTHENTICATION: -2010,
        FACEBOOK_COMMUNICATION: -2011,
        GOOGLE_AUTHENTICATION: -2013,
        GOOGLE_COMMUNICATION: -2014,
        FACEBOOK_ACCOUNT_NOT_FOUND: -2016,
        GOOGLE_ACCOUNT_NOT_FOUND: -2017,
    },
};
exports.PortalLoginCodes = {
    sucess: 200,
    error: {
        USER_NOT_FOUND: 404,
        INVALID_ACCOUNT: 401,
    },
};
exports.RegisterCodes = {
    success: 2002,
    error: {
        EMAIl_ALREADY_IN_USE: -2003,
        FACEBOOK_ALREADY_IN_USE: -2012,
        GOOGLE_ALREADY_IN_USE: -2015,
    },
};
exports.EmailConfirmationCodes = {
    SUCCESS: 2009,
    error: {
        EMAIL_TOKEN_NOT_FOUND: -2007,
        INVALID_EMAIL_TOKEN: -2008,
    },
};
exports.ForgotPasswordCodes = {
    SUCCESS: 2003,
    error: {
        USER_NOT_FOUND: -2004,
    },
};
exports.ResetPasswordCodes = {
    SUCCESS: 2010,
    error: {
        TOKEN_NOT_FOUND: -2009,
        INVALID_TOKEN: -2008,
    },
};
exports.ChangePasswordCodes = {
    SUCCESS: 2004,
    error: {
        INVALID_OLD_PASSWORD: -2005,
    },
};
exports.GetMyProfileCodes = {
    SUCCESS: 2006,
    UNAUTHORIZED: -1001,
};
exports.EditMyProfileCodes = {
    success: {
        PROFILE_UPDATED: 2008,
        EMAIL_CHANGED: 2007,
        EMAIL_NOT_CHANGED: 2008,
    },
    error: {
        EMAIL_ALREADY_IN_USE: -2003,
        EMAIL_TOKEN_NOT_FOUND: -2007,
    },
    UNAUTHORIZED: -1001,
};
exports.BookingReservationCodes = {
    success: {
        BOOKING_CREATED: 16000,
        ROOM_FOUNDED: 7001,
    },
    error: {
        BEDS_IP_UNAUTHORIZED: 1022,
        BEDS_NO_AVAILABILITY: 6007,
        BOOKING_FAILED: -14000,
        ROOM_NOTFOUND: -7000,
        VALIDATION_FIELDS: -1005,
    },
};
exports.DeleteProfileCodes = {
    success: 2011,
};
exports.BookingCodes = {
    success: 18001,
    error: -16000,
    UNAUTHORIZED: -1001,
};
exports.PreCheckin = {
    success: 16001,
    error: -14002,
};
exports.PaymentCodes = {
    error: {
        VALIDATION_FIELDS: -1005,
    },
};
exports.ForgotPasswordPortalInvestidorCodes = {
    SUCCESS: 200,
    error: {
        USER_NOT_FOUND: 404,
    },
};
