// React Imports
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Ant Design, Icons and Stylesheet
import { Result, Button } from 'antd';
import './NotFound.scss';

import { routesPaths } from '../../routes/RoutesPaths';

const NotFound = props => {
  const subTitle =
    props.location.state && props.location.state.subTitle ? props.location.state.subTitle : 'Página não encontrada.';
  return (
    <div className="notFound">
      <Result status="404" title="Erro 404" subTitle={subTitle}>
        <Button type="primary" className="btn-go-back" onClick={() => props.history.push(routesPaths.root)}>
          VOLTAR À PÁGINA INICIAL
        </Button>
      </Result>
    </div>
  );
};

NotFound.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

NotFound.defaultProps = {};

export default withRouter(NotFound);
