import request from 'superagent';
import { API_URL, PORTAL_API_URL } from '../assets/constants/constants';
import { HTTPCodes } from '../assets/constants/response_codes';
import { getAccessToken } from '../utils/users';

const sampleGET = () =>
  new Promise((resolve, reject) =>
    request.get('https://get.geojs.io/v1/ip/country.json?ip=8.8.8.8').end((error, response) => {
      if (response) {
        setTimeout(() => {
          resolve(response.body);
        }, 1000);
      } else {
        reject(error);
      }
    }),
  );

const fetchCEP = cep =>
  new Promise((resolve, reject) =>
    request.get(`https://viacep.com.br/ws/${cep}/json/`).end((error, response) => {
      if (response) {
        resolve(response.body);
      } else {
        reject(error);
      }
    }),
  );

// const onRefreshToken = (callback, callbackParams) => {
//   const payload = {
//     command: {
//       refreshToken: `${getRefreshToken()}`,
//     },
//   };

//   return new Promise(resolve =>
//     request
//       .post(API_URL + AUTH_URL)
//       .send(payload)
//       .set('Content-Type', 'application/json')
//       .type('application/json')
//       .end(async (error, response) => {
//         if (response) {
//           if (response.body.error && response.body.error.code === INVALID_REFRESH_TOKEN_ERROR_CODE) {
//             removeUserFromStorage();
//             window.location.href = routesPaths.login;
//           } else {
//            setUserOnStorage(response.body);

//             const newResponse = await callback({ ...callbackParams });

//             resolve(newResponse);
//           }
//         }
//       }),
//   );
// };

const onEndRequest = (response, error, callbackFunction, { url, query, params, clientOptions, id }) =>
  new Promise(async (resolve, reject) => {
    if (response) {
      if (response.body.error && response.body.error.code === HTTPCodes.INVALID_ACCESS_TOKEN_ERROR_CODE) {
        const refreshResponse = await onRefreshToken(callbackFunction, {
          url,
          query,
          params,
          clientOptions,
          id,
        });

        resolve(refreshResponse);
      } else if (clientOptions && 'returnAll' in clientOptions && clientOptions.returnAll === true) {
        resolve(response);
      } else {
        resolve(response.body);
      }
    } else {
      reject(error);
    }
  });

const get = ({ url, query, params, clientOptions }) => {
  const accessToken = getAccessToken();

  return new Promise(resolve =>
    request
      .get(API_URL + url)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .query(query || null)
      .send(params || null)
      .end((error, response) => {
        resolve(
          onEndRequest(response, error, get, {
            url,
            query,
            params,
            clientOptions,
          }),
        );
      }),
  );
};

const post = ({ url, query, params, clientOptions }) => {
  const accessToken = getAccessToken();

  return new Promise(resolve =>
    request
      .post(API_URL + url)
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(response, error, post, {
            url,
            query,
            params,
            clientOptions,
          }),
        );
      }),
  );
};

const put = ({ url, params, query, clientOptions }) => {
  const accessToken = getAccessToken();

  return new Promise(resolve =>
    request
      .put(API_URL + url)
      .query(query || null)
      .send(params || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(response, error, put, {
            url,
            query,
            params,
            clientOptions,
          }),
        );
      }),
  );
};

const patch = ({ url, params, id, query, clientOptions }) => {
  const accessToken = getAccessToken();

  return new Promise(resolve =>
    request
      .patch(API_URL + url + (id || ''))
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(response, error, patch, {
            url,
            query,
            params,
            clientOptions,
            id,
          }),
        );
      }),
  );
};

const del = ({ url, params, query, clientOptions }) => {
  const accessToken = getAccessToken();

  return new Promise(resolve =>
    request
      .delete(API_URL + url)
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(response, error, del, {
            url,
            query,
            params,
            clientOptions,
          }),
        );
      }),
  );
};

const download = ({ url }) => {
  const accessToken = getAccessToken();

  return new Promise(resolve =>
    request
      .get(API_URL + url)
      .set('Authorization', accessToken ? `Bearer ${accessToken}` : null)
      .responseType('blob')
      .end((err, response) => {
        if (response) {
          resolve(response);
        } else {
          resolve(err);
        }
      }),
  );
};

const CREATEOWNERS = '/owners/create';
export const createOwner = params =>
  new Promise(resolve =>
    request
      .post(API_URL + CREATEOWNERS)
      .send(params || null)
      .set('Accept', 'application/json')
      .type('application/json')
      .end((error, response) => {
        if (response) {
          resolve(response.body);
        } else {
          resolve(error);
        }
      }),
  );

const CREATEOWNERSWITHPRIORITY = '/owners/createOwnerWithPriority';
export const createOwnerWithPriority = params =>
  new Promise(resolve =>
    request
      .post(API_URL + CREATEOWNERSWITHPRIORITY)
      .send(params || null)
      .set('Accept', 'application/json')
      .type('application/json')
      .end((error, response) => {
        if (response) {
          resolve(response.body);
        } else {
          resolve(error);
        }
      }),
  );

const AVAILABLE_ROOMS_URL = '/rooms/available';
export const getAvailableRooms = (query = { pageNumber: 0 }) =>
  new Promise(resolve =>
    request
      .get(API_URL + AVAILABLE_ROOMS_URL)
      .query(query || null)
      .end((error, response) => {
        if (response) {
          resolve(response.body);
        } else {
          resolve({ message: error, error: true });
        }
      }),
  );

const NOT_AVAILABLE_ROOM_DATES_URL = '/rooms/NotAvailable';
export const getNotAvailableDates = (query = {}) =>
  new Promise(resolve =>
    request
      .get(API_URL + NOT_AVAILABLE_ROOM_DATES_URL)
      .query(query || null)
      .end((error, response) => {
        if (response) {
          resolve(response.body);
        } else {
          resolve(error);
        }
      }),
  );

const ROOM_URL = '/rooms/';
export const getRoom = roomId =>
  new Promise((resolve, reject) =>
    request.get(API_URL + ROOM_URL + roomId).end((error, response) => {
      if (response) {
        resolve(response.body);
      } else {
        reject(error);
      }
    }),
  );

const AVAILABLE_ROOM_DETAILS_URL = '/rooms/availableRoomDetails';
export const getAvailableRoomDetails = query =>
  new Promise(resolve =>
    request
      .get(API_URL + AVAILABLE_ROOM_DETAILS_URL)
      .query(query || null)
      .end((error, response) => {
        if (response) {
          resolve(response.body);
        } else {
          resolve(error);
        }
      }),
  );

const portalPost = ({ url, query, params, clientOptions }) => {
  return new Promise(resolve =>
    request
      .post(PORTAL_API_URL + url)
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(response, error, post, {
            url,
            query,
            params,
            clientOptions,
          }),
        );
      }),
  );
};

const portalPut = ({ url, query, params, clientOptions }) => {
  return new Promise(resolve =>
    request
      .put(PORTAL_API_URL + url)
      .send(params || null)
      .query(query || null)
      .set('Accept', 'application/json')
      .type('application/json')
      .end((error, response) => {
        resolve(
          onEndRequest(response, error, post, {
            url,
            query,
            params,
            clientOptions,
          }),
        );
      }),
  );
};

export { sampleGET, fetchCEP, get, post, put, patch, del, download, portalPost, portalPut};
