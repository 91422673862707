"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Icon = exports.Group = exports.Text = exports.ContainerBody = void 0;
const styled_components_1 = __importStar(require("styled-components"));
exports.ContainerBody = styled_components_1.default.div `
  background-color: #f6f6f6;

  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  ${props => props.disabled === true &&
    (0, styled_components_1.css) `
      display: none;
    `};

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
exports.Text = styled_components_1.default.span `
  ${props => (0, styled_components_1.css) `
    color: ${props.color || '#000'};
    font-weight: ${props.decoration || 'normal'};
    font-size: ${(props.size && (props.size - 5) * 0.06) || 13 * 0.06}rem;
    cursor: ${props.clickable ? 'pointer' : 'default'};
    @media screen and (max-width: 900px) {
      font-size: ${(props.size && (props.size - 7) * 0.06) || 13 * 0.06}rem;
    }
  `}
`;
exports.Group = styled_components_1.default.div `
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  ${props => props.fullWidth &&
    (0, styled_components_1.css) `
      width: 97%;
    `}
`;
exports.Icon = styled_components_1.default.img `
  width: ${props => props.size || 25}px;
  margin: 0 5px;
`;
