import React from 'react';
import PropTypes from 'prop-types';
import './VisualCheckbox.scss';

export default function VisualCheckbox(props) {
  return (
    <div className="VisualCheckbox">
      {props.active && <div className="checked" />}
    </div>
  );
}

VisualCheckbox.propTypes = {
  active: PropTypes.bool,
};

VisualCheckbox.defaultProps = {
  active: false,
};
