import React from 'react';
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import base64arraybuffer from 'base64-arraybuffer';
import { message } from 'antd';
import { BROWSER_NAMES, ENCRYPTION_KEY, LOCAL_STORAGE } from '../assets/constants/constants';
import { downloadTermsAndConditionsFile } from '../dataSource/file_requests';
import { keyVariables } from './users';

// TODO: [XP360-620] Transpilar o arquivo Helpers.js para TS
const encrypty = value => AES.encrypt(value, ENCRYPTION_KEY).toString();

const decrypty = value => {
  if (value) {
    return AES.decrypt(value, ENCRYPTION_KEY).toString(enc);
  }

  return null;
};

const convertBase64ToArrayBuffer = image => {
  const type = image[0].type.replace('image/', '');
  const imageURL = image[0].thumbUrl
    .replace(`data:image/${type};base64,`, '')
    .replace('data:image/jpg;base64,', '')
    .replace('data:image/jpeg;base64,', '')
    .replace('data:image/png;base64,', '');
  const arrayBuffer = base64arraybuffer.decode(imageURL);
  const int8array = new Int8Array(arrayBuffer);

  return Object.values(int8array);
};

const objectIsEmpty = object => !Object.keys(object).length;

const isEmpty = value => value === null || value === undefined || value === '';

const downloadArchive = (buffer, type, fileName) => {
  const blob = new Blob([buffer], { type });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.style = 'visibility:hidden';
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadPDF = file => {
  if (file.id) {
    downloadTermsAndConditionsFile()
      .then(response => {
        downloadArchive(response, 'application/pdf', file.name);
      })
      .catch(() => {
        message.error('Erro ao fazer download do arquivo');
      });
  } else {
    downloadArchive(file.originFileObj, 'application/pdf', file.name);
  }
};

const downloadFile = (buffer, fileName) => {
  const blob = new Blob([buffer]);
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.style = 'visibility:hidden';
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const splitFullName = fullName => {
  const regex = /\w+\s\w+(?=\s)|\w+/g;
  const regexRemoveAccent = /[\u0300-\u036f]/g;
  const nameAux = fullName.normalize('NFD').replace(regexRemoveAccent, '');
  const [firstName, lastName, ...lastsNames] = nameAux.trim().match(regex);
  return [firstName, lastName, ...lastsNames];
};

const handleCleanBookingStorage = () => {
  localStorage.removeItem('XP360:beds_bookId');
  localStorage.removeItem(LOCAL_STORAGE.bookingId);
  localStorage.removeItem(LOCAL_STORAGE.confirmValues);
  localStorage.removeItem(keyVariables.paymentGuestValues);
  localStorage.removeItem('XP360:bookingSaved');
  localStorage.setItem('XP360:filledGuestsNumber', encrypty('0'));
};

const myTrim = value => {
  if (value) {
    let result = value;
    try {
      result = value.trim();
    } catch (error) {
      console.error(error.message);
    }
    return result;
  }
  return '';
};

const isBrowserSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window[BROWSER_NAMES.SAFARI] || (typeof safari !== 'undefined' && safari.pushNotification))
  );
};

const encryptStorage = (key, T) => {
  try {
    localStorage.setItem(key, encrypty(JSON.stringify(T)));
  } catch (e) {
    throw new Error(`Encrypt with localStorage is failed. ${e}`);
  }
};

const decryptStorage = key => {
  try {
    const value = decrypty(localStorage.getItem(key));
    return JSON.parse(value);
  } catch (e) {
    throw new Error(`Decrypty with localStorage is failed. ${e}`);
  }
};

const messageErrorsValidationsFieldsCheckin = error => {
  const ordinalNames = ['Primeiro', 'Segundo', 'Terceiro', 'Quarto', 'Quinto'];

  const msg = [];
  let msgGuest = '';
  if (error.length > 0) {
    for (let i = 0; i < error.length; i++) {
      msg.push(error[i].errors[0]);
    }
  }

  if (error.errorFields.length > 0) {
    for (let i = 0; i < error.errorFields.length; i++) {
      const [form, key, type, numberGuest] = error.errorFields[i].name;
      if (type === 'guests') msgGuest = `${ordinalNames[numberGuest + 1]} Hóspede: `;
      msg.push(msgGuest + error.errorFields[i].errors[0]);
    }
  }

  const descriptionElement = (
    <>
      {`Opsss valide os campos obrigatórios ${msg.length > 0 ? ':' : ''}`}
      <br />
      {msg.map(m => (
        <>
          {m} <br />
        </>
      ))}
    </>
  );

  return descriptionElement;
};

const getTabActiveGuestError = error => {
  let tabActive;
  if (error.errorFields.length > 0) {
    for (let i = 0; i < error.errorFields.length; i++) {
      const [form, key, type, numberGuest] = error.errorFields[i].name;
      if (type === 'guests') tabActive = String(numberGuest);
    }
  }
  return tabActive;
};

export {
  encrypty,
  decrypty,
  convertBase64ToArrayBuffer,
  objectIsEmpty,
  isEmpty,
  downloadArchive,
  downloadPDF,
  downloadFile,
  splitFullName,
  handleCleanBookingStorage,
  myTrim,
  isBrowserSafari,
  encryptStorage,
  decryptStorage,
  messageErrorsValidationsFieldsCheckin,
  getTabActiveGuestError,
};
